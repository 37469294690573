<template>
  <v-container fluid v-if="tiene_empresa">
    <v-data-table
      v-if="flag_csv == 'no'"
      :headers="headers"
      :items="items"
      :search="search"
      dense
      :loading="loading_facturas"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon @click="listarFacturas()" v-bind="attrs" v-on="on">
                fa-solid fa-rotate-right
              </v-icon>
            </template>
            <span>Actualizar</span>
          </v-tooltip>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-row>
            <v-col cols="3">
              <v-autocomplete
                v-model="ver_tipo_docs"
                label="Tipo Documento"
                :items="['FEX', 'NRM']"
                outlined
                dense
                hide-details
                @input="localStorageTipoDoc()"
              ></v-autocomplete>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="search"
                label="Buscar"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-spacer></v-spacer>
          <v-btn class="mx-3" color="green" dark @click="facturasCSV()"
            >Nuevo CSV</v-btn
          >
          <v-btn color="primary" @click="nuevaFactura()"> Nuevo </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:[`item.fecha`]="{ item }">
        <span> {{ formatoFecha(item.fecha) }}</span>
      </template>

      <template v-slot:[`item.total_pagar`]="{ item }">
        <span> $ {{ Number(item.total_pagar).toFixed(2) }}</span>
      </template>

      <template v-slot:[`item.estatus`]="{ item }">
        <v-tooltip
          left
          color="primary"
          v-if="
            item.sello_recepcion == '' && item.resolucion_invalidacion == null
          "
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="primary" v-bind="attrs" v-on="on"
              >fa-regular fa-floppy-disk</v-icon
            >
          </template>
          <span>GUARDADO</span>
        </v-tooltip>

        <v-tooltip
          left
          color="success"
          v-else-if="
            item.sello_recepcion != '' && item.resolucion_invalidacion == null
          "
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="success" v-bind="attrs" v-on="on"
              >fa-regular fa-circle-check</v-icon
            >
          </template>
          <span>PROCESADO</span>
        </v-tooltip>

        <v-tooltip
          left
          color="error"
          v-else-if="
            item.resolucion_invalidacion != '' &&
            item.resolucion_invalidacion != null
          "
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="error" v-bind="attrs" v-on="on"
              >fa-regular fa-circle-xmark</v-icon
            >
          </template>
          <span>ANULADO</span>
        </v-tooltip>

        <v-tooltip left color="error" v-else-if="item.estatus == 'ERROR'">
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="error" v-bind="attrs" v-on="on"
              >fa-solid fa-circle-exclamation</v-icon
            >
          </template>
          <span>ERROR</span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.action_edit`]="{ item }">
        <v-icon @click="editarFactura(item)">
          fa-solid fa-pen-to-square
        </v-icon>
      </template>

      <template v-slot:[`item.action_send_dte`]="{ item }">
        <v-icon @click="enviarDTE(item)" :disabled="item.sello_recepcion != ''">
          fa-regular fa-paper-plane
        </v-icon>
      </template>

      <template v-slot:[`item.action_gen_preview`]="{ item }">
        <v-icon
          @click="vistaPreviaPdf(item)"
          :disabled="item.sello_recepcion != ''"
        >
          fa-regular fa-file-pdf
        </v-icon>
      </template>

      <template v-slot:[`item.action_view_dte`]="{ item }">
        <v-icon
          @click="vistaPreviaPdfSello(item)"
          :disabled="item.sello_recepcion == ''"
        >
          fa-regular fa-file-pdf
        </v-icon>
      </template>
    </v-data-table>

    <v-data-table
      v-if="flag_csv == 'si'"
      :headers="headers_csv"
      :items="items_csv"
    >
      <template v-slot:top>
        <v-row>
          <v-col cols="4">
            <v-file-input
              v-model="data_file_input"
              label="Archivo csv"
              outlined
              dense
              hide-details
              multiple
              @change="procesarCSV"
            ></v-file-input>
          </v-col>
          <v-col cols="auto">
            <v-btn color="primary" @click="guardarCSV()">Guardar</v-btn>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="auto">
            <v-btn color="grey" dark @click="flag_csv = 'no'">Regresar</v-btn>
          </v-col>
        </v-row>
      </template>
    </v-data-table>

    <v-dialog v-model="dialog" persistent width="3000" scrollable>
      <v-card>
        <v-toolbar elevation="0" v-if="false">
          <v-row justify="end" class="mx-8">
            <v-col cols="auto" align-self="center">
              <v-checkbox
                v-model="extingido_cautelado"
                label="Extinguido o Cautelado"
                hide-details
              ></v-checkbox>
            </v-col>
            <v-col cols="3">
              <v-text-field
                v-model="codigo_bien"
                label="Código del bien"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </v-toolbar>
        <v-card-text>
          <!-- PRIMERA PARTE CORRELATIVOS-->
          <v-card class="mx-8 my-2">
            <v-card-text>
              <v-row no-gutters>
                <v-col cols="12" md="6" class="pa-1">
                  <v-text-field
                    v-model="numero_control"
                    label="Numero de control"
                    outlined
                    dense
                    hide-details
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="pa-1">
                  <v-text-field
                    v-model="codigo_generacion"
                    label="Codígo de generación"
                    outlined
                    dense
                    hide-details
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="pa-1">
                  <v-text-field
                    v-model="fecha_hora"
                    label="Fecha y hora"
                    type="datetime-local"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3" class="pa-1">
                  <v-text-field
                    v-model="sello_recepcion"
                    label="Sello de recepción"
                    outlined
                    dense
                    hide-details
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3" class="pa-1">
                  <v-text-field
                    v-model="fecha_recepcion"
                    label="Fecha de recepción"
                    type="datetime-local"
                    outlined
                    dense
                    hide-details
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <!-- SEGUNDA PARTE SUCARSAL, TIPO DOC-->
          <v-card class="mx-8 mb-2">
            <v-card-text>
              <v-row no-gutters>
                <v-col cols="12" class="pa-1">
                  <v-autocomplete
                    v-model="sucursal"
                    :items="items_sucursales"
                    item-text="nombre"
                    item-value="id_sucursal"
                    label="Sucursal"
                    outlined
                    dense
                    hide-details
                    @input="listarTipoDocs()"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="4" class="pa-1">
                  <v-autocomplete
                    v-model="tipo_doc_fiscal"
                    :items="items_tipo_doc_fiscales"
                    item-text="nombre_doc_fiscal"
                    item-value="id_tipo_doc_fiscal"
                    label="Tipo Documento"
                    outlined
                    dense
                    hide-details
                    return-object
                    @change="listarTipoDocs()"
                    @input="listarDocSeleccionado()"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="4" align-self="center">
                  <v-btn
                    block
                    @click="listarCCF()"
                    :disabled="
                      tipo_doc_fiscal.cod_doc_fiscal != 'NCR' &&
                      tipo_doc_fiscal.cod_doc_fiscal != 'NDB' &&
                      tipo_doc_fiscal.cod_doc_fiscal != 'NCR' &&
                      tipo_doc_fiscal.cod_doc_fiscal != 'NDB'
                    "
                  >
                    <v-icon left>fa-solid fa-file-invoice-dollar</v-icon>
                    BUSCAR CCF
                  </v-btn>
                </v-col>
                <v-col cols="12" md="4" class="pa-1">
                  <v-autocomplete
                    v-model="tipo_pago"
                    :items="items_tipo_pago"
                    label="Tipo Pago"
                    outlined
                    dense
                    hide-details
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <!-- TERCERA PARTE CLIENTE-->
          <v-card class="mx-8 mb-2">
            <v-card-text>
              <v-row no-gutters>
                <v-col cols="12" md="5" class="pa-1">
                  <v-autocomplete
                    v-model="cliente"
                    :items="items_clientes"
                    item-text="nombre"
                    item-value="codigo"
                    label="Cliente"
                    outlined
                    dense
                    hide-details
                    return-object
                    @input="setCliente()"
                  >
                    <template v-slot:selection="data">
                      {{ data.item.codigo }}
                    </template>
                    <template v-slot:item="data">
                      {{ data.item.codigo }} {{ data.item.nombre }}
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="3" class="pa-1">
                  <v-text-field
                    v-model="registro"
                    label="Registro"
                    outlined
                    dense
                    hide-details
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2" class="pa-1">
                  <v-text-field
                    v-model="identifiador"
                    label="Identificador"
                    outlined
                    dense
                    hide-details
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2" class="pa-1">
                  <v-text-field
                    v-model="telefono"
                    label="Teléfono"
                    outlined
                    dense
                    hide-details
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="7" class="pa-1">
                  <v-text-field
                    v-model="nombre"
                    label="Nombre"
                    outlined
                    dense
                    hide-details
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2" class="pa-1">
                  <v-text-field
                    v-model="tipo"
                    label="Tipo"
                    outlined
                    dense
                    hide-details
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3" class="pa-1">
                  <v-text-field
                    v-model="num_documento"
                    label="Documento"
                    outlined
                    dense
                    hide-details
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2" class="pa-1">
                  <v-autocomplete
                    v-model="consignado_a"
                    :items="items_id_clintes_consignados"
                    item-text="consignatarios"
                    item-value="consignatarios"
                    label="Consignado_a"
                    @change="setConsignado"
                    outlined
                    dense
                    hide-details
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="3" class="pa-1">
                  <v-text-field
                    v-model="direccion"
                    label="Dirección"
                    outlined
                    dense
                    hide-details
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2" class="pa-1">
                  <v-autocomplete
                    v-model="incoterms"
                    :items="items_id_incoterms"
                    item-text="codigo"
                    item-value="codigo"
                    label="INCOTERMS"
                    outlined
                    dense
                    hide-details
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="3" class="pa-1">
                  <v-text-field
                    v-model="pais_origen"
                    label="País origen"
                    outlined
                    dense
                    hide-details
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="2" class="pa-1">
                  <v-text-field
                    v-model="peso_bruto"
                    label="Peso bruto"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <!-- TABLA SERVICIOS -->
          <v-card class="mx-8 mb-2 mt-3">
            <v-card-text class="mt-3">
              <v-data-table
                :headers="headers_productos"
                :items="items_productos"
                dense
                class="mt-3"
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-row
                      v-if="$vuetify.breakpoint.mdAndUp"
                      no-gutters
                      justify="space-around"
                      align="center"
                    >
                      <v-col cols="1">
                        <v-text-field
                          v-model="estilo"
                          label="Estilo"
                          outlined
                          dense
                          hide-details
                          :disabled="cantidad <= 0 || tipo_doc_fiscal == ''"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="1">
                        <v-text-field
                          v-model="cantidad"
                          label="Cantidad"
                          outlined
                          dense
                          hide-details
                          type="number"
                          :disabled="tipo_doc_fiscal == ''"
                          @input="calculoCantidad"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="1">
                        <v-autocomplete
                          v-model="unidad_medida"
                          :items="items_unidad_medida"
                          label="Medida"
                          outlined
                          dense
                          hide-details
                          @input="asignarProducto()"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="auto">
                        <v-btn
                          outlined
                          @click="dialog_servicios = true"
                          :disabled="tipo_doc_fiscal == ''"
                        >
                          <v-icon>fa-solid fa-magnifying-glass</v-icon>
                        </v-btn>
                      </v-col>
                      <v-col cols="3">
                        <v-text-field
                          v-model="descripcion"
                          label="Descripción"
                          outlined
                          dense
                          hide-details
                        ></v-text-field>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field
                          v-model="descripcion2"
                          label="Descripción 2"
                          outlined
                          dense
                          hide-details
                        ></v-text-field>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field
                          v-model="precio"
                          label="Precio Unitario"
                          outlined
                          dense
                          hide-details
                          type="number"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="1">
                        <v-text-field
                          v-model="total"
                          label="Total"
                          outlined
                          dense
                          hide-details
                          type="number"
                        ></v-text-field>
                      </v-col>

                      <v-row class="mt-1">
                        <v-col cols="6" md="3"></v-col>
                        <v-col cols="6" md="2">
                          <v-text-field
                            v-model="pais"
                            label="Pais de Origen"
                            outlined
                            dense
                            hide-details
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6" md="2">
                          <v-text-field
                            v-model="peso"
                            label="Peso"
                            outlined
                            dense
                            hide-details
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6" md="2">
                          <v-text-field
                            v-model="codigo_aduana"
                            label="Codigo Aduana"
                            outlined
                            dense
                            hide-details
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12"></v-col>
                      </v-row>
                    </v-row>

                    <v-menu bottom left v-if="$vuetify.breakpoint.smAndDown">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" v-bind="attrs" v-on="on"
                          >Detalles</v-btn
                        >
                      </template>
                      <v-card width="500">
                        <v-card-text>
                          <v-row justify="space-around" align="center">
                            <v-col cols="12">
                              <v-text-field
                                v-model="cantidad"
                                label="Cantidad"
                                outlined
                                dense
                                hide-details
                                type="number"
                                :disabled="tipo_doc_fiscal == ''"
                                @input="calculoCantidad"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="1">
                              <v-autocomplete
                                v-model="unidad_medida"
                                :items="items_unidad_medida"
                                label="Unidad Medida"
                                outlined
                                dense
                                hide-details
                                @input="asignarProducto()"
                              ></v-autocomplete>
                            </v-col>
                            <v-col cols="12">
                              <v-text-field
                                v-model="producto"
                                label="Producto"
                                outlined
                                dense
                                hide-details
                                :disabled="
                                  cantidad <= 0 || tipo_doc_fiscal == ''
                                "
                                @input="asignarProducto()"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                              <v-btn
                                outlined
                                @click="dialog_servicios = true"
                                block
                              >
                                <v-icon>fa-solid fa-plus</v-icon>
                              </v-btn>
                            </v-col>
                            <v-col cols="12">
                              <v-text-field
                                v-model="precio"
                                label="Precio"
                                outlined
                                dense
                                hide-details
                                type="number"
                                :disabled="descripcion == ''"
                                @change="modificarPrecio()"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                              <v-text-field
                                v-model="descuento_prod"
                                label="%Desc"
                                outlined
                                dense
                                hide-details
                                type="number"
                                :disabled="descripcion == ''"
                                @change="calculoDescuento()"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                              <v-text-field
                                v-model="total"
                                label="Total"
                                outlined
                                dense
                                hide-details
                                type="number"
                                disabled
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-menu>
                    <v-spacer></v-spacer>
                    <v-btn
                      class="mx-2"
                      @click="agregarProducto()"
                      :disabled="sello_recepcion != ''"
                    >
                      <v-icon color="green">fa-solid fa-square-check</v-icon>
                    </v-btn>
                  </v-toolbar>
                </template>

                <template v-slot:[`item.estilo`]="{ item }">
                  <v-edit-dialog
                    :return-value.sync="item.estilo"
                    large
                    save-text="Guardar"
                    cancel-text="Cancelar"
                  >
                    {{ item.estilo }}
                    <template v-slot:input>
                      <v-text-field
                        v-model="item.estilo"
                        single-line
                        hide-details
                        dense
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.cantidad`]="{ item }">
                  <v-edit-dialog
                    :return-value.sync="item.cantidad"
                    large
                    save-text="Guardar"
                    cancel-text="Cancelar"
                  >
                    {{ item.cantidad }}
                    <template v-slot:input>
                      <v-text-field
                        v-model="item.cantidad"
                        single-line
                        hide-details
                        dense
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.unidad_medida`]="{ item }">
                  <v-edit-dialog
                    :return-value.sync="item.unidad_medida"
                    large
                    save-text="Guardar"
                    cancel-text="Cancelar"
                  >
                    {{ item.unidad_medida }}
                    <template v-slot:input>
                      <v-text-field
                        v-model="item.unidad_medida"
                        single-line
                        hide-details
                        dense
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.descripcion`]="{ item }">
                  <v-edit-dialog
                    :return-value.sync="item.descripcion"
                    large
                    save-text="Guardar"
                    cancel-text="Cancelar"
                  >
                    {{ item.descripcion }}
                    <template v-slot:input>
                      <v-text-field
                        v-model="item.descripcion"
                        single-line
                        hide-details
                        dense
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.descripcion2`]="{ item }">
                  <v-edit-dialog
                    :return-value.sync="item.descripcion2"
                    large
                    save-text="Guardar"
                    cancel-text="Cancelar"
                  >
                    {{ item.descripcion2 }}
                    <template v-slot:input>
                      <v-text-field
                        v-model="item.descripcion2"
                        single-line
                        hide-details
                        dense
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.precio`]="{ item }">
                  <v-edit-dialog
                    :return-value.sync="item.precio"
                    large
                    save-text="Guardar"
                    cancel-text="Cancelar"
                  >
                    <span> $ {{ Number(item.precio).toFixed(4) }}</span>
                    <template v-slot:input>
                      <v-text-field
                        v-model="item.precio"
                        single-line
                        hide-details
                        dense
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.total`]="{ item }">
                  <v-edit-dialog
                    :return-value.sync="item.total"
                    large
                    save-text="Guardar"
                    cancel-text="Cancelar"
                    @save="editarDetalleServicio(item)"
                  >
                    <span> $ {{ Number(item.total).toFixed(2) }}</span>
                    <template v-slot:input>
                      <v-text-field
                        v-model="item.total"
                        single-line
                        hide-details
                        dense
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>

          <!-- ULTIMA PARTE CALCULOS-->
          <v-row no-gutters justify="center" align="center" class="mx-8">
            <v-card width="150" class="ma-1">
              <v-col cols="12">
                <v-text-field
                  v-model="otros_cargos"
                  label="Otros cargos"
                  prepend-inner-icon="fa-solid fa-dollar-sign"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="anticipo"
                  label="Anticipo"
                  prepend-inner-icon="fa-solid fa-dollar-sign"
                  outlined
                  dense
                  hide-details
                  readonly
                ></v-text-field>
              </v-col>
            </v-card>

            <v-card width="150" class="ma-1">
              <v-col cols="12">
                <v-text-field
                  v-model="calculoSubTotal"
                  label="Sub-total"
                  prepend-inner-icon="fa-solid fa-dollar-sign"
                  outlined
                  dense
                  hide-details
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="descuento"
                  label="Descuento"
                  prepend-inner-icon="fa-solid fa-dollar-sign"
                  outlined
                  dense
                  hide-details
                  :readonly="flag_descuento"
                ></v-text-field>
              </v-col>
            </v-card>

            <v-card width="150" class="ma-1">
              <v-col cols="12">
                <v-text-field
                  v-model="gravadas"
                  label="Gravadas"
                  prepend-inner-icon="fa-solid fa-dollar-sign"
                  outlined
                  dense
                  hide-details
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="iva"
                  label="IVA"
                  prepend-inner-icon="fa-solid fa-dollar-sign"
                  outlined
                  dense
                  hide-details
                  readonly
                ></v-text-field>
              </v-col>
            </v-card>

            <v-card width="150" class="ma-1">
              <v-col cols="12">
                <v-text-field
                  v-model="exentas"
                  label="Exentas"
                  prepend-inner-icon="fa-solid fa-dollar-sign"
                  outlined
                  dense
                  hide-details
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="no_sujetas"
                  label="No sujetas"
                  prepend-inner-icon="fa-solid fa-dollar-sign"
                  outlined
                  dense
                  hide-details
                  readonly
                ></v-text-field>
              </v-col>
            </v-card>

            <v-card width="150" class="ma-1">
              <v-col cols="12">
                <v-text-field
                  v-model="retencion"
                  label="Retención"
                  prepend-inner-icon="fa-solid fa-dollar-sign"
                  outlined
                  dense
                  hide-details
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="percepcion"
                  label="Percepción"
                  prepend-inner-icon="fa-solid fa-dollar-sign"
                  outlined
                  dense
                  hide-details
                  readonly
                ></v-text-field>
              </v-col>
            </v-card>

            <v-card width="150" class="ma-1">
              <v-col cols="12">
                <v-text-field
                  v-model="calculoTotalOperacion"
                  label="Total operación"
                  prepend-inner-icon="fa-solid fa-dollar-sign"
                  outlined
                  dense
                  hide-details
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="calculoTotalPagar"
                  label="Total a pagar"
                  prepend-inner-icon="fa-solid fa-dollar-sign"
                  outlined
                  dense
                  hide-details
                  readonly
                ></v-text-field>
              </v-col>
            </v-card>
          </v-row>
        </v-card-text>

        <v-card-actions style="background-color: white">
          <v-btn
            color="primary"
            @click="crearfactura()"
            v-if="flag_new_edit == 1"
            >Guardar</v-btn
          >
          <v-btn
            color="primary"
            @click="actualizarfactura()"
            v-if="flag_new_edit == 2"
            :disabled="sello_recepcion != ''"
            >Actualizar</v-btn
          >
          <v-btn
            color="red"
            @click="confirmarAnularDocumento()"
            v-if="
              (flag_new_edit == 2 &&
                $store.state.usuario.rol == 'Administrador') ||
              ($store.state.usuario.rol == 'IT' && sello_recepcion != '')
            "
            dark
            >Anular</v-btn
          >
          <v-btn
            color="red"
            @click="eliminarFactura()"
            v-if="
              flag_new_edit === 2 &&
              ($store.state.usuario.rol === 'Administrador' ||
                $store.state.usuario.rol === 'IT') &&
              sello_recepcion === ''
            "
            :loading="loading_eliminar_factura"
            dark
            >Eliminar</v-btn
          >
          <v-btn
            @click="reenvioCorreo()"
            color="primary"
            v-if="sello_recepcion != '' && false"
            >Reenviar correo</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn text @click="cerrarDialog">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_clientes">
      <v-data-table
        :headers="headers_clientes"
        :items="items_clientes"
        @dblclick:row="setCliente"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Clientes</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <div style="color: black">[Hacer doble click para seleccionar]</div>
          </v-toolbar>
        </template>
      </v-data-table>
    </v-dialog>

    <v-dialog v-model="dialog_servicios" class="mt-3">
      <v-data-table
        :headers="headers_servicios"
        :items="items_servicios"
        @dblclick:row="setServicio"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Servicios</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <div style="color: black">[Hacer doble click para seleccionar]</div>
          </v-toolbar>
        </template>
      </v-data-table>
    </v-dialog>

    <v-dialog v-model="dialog_eliminar_servicio" width="500">
      <v-card>
        <v-card-title>¿Esta seguro de eliminar este servicio?</v-card-title>
        <v-card-actions>
          <v-btn color="red" dark>Eliminar</v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog_eliminar_servicio = false">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_tabla_CCF" width="1500" scrollable>
      <v-card>
        <v-card-text class="pa-0">
          <v-data-table
            v-model="items_tipo_doc_ncr_ndb"
            :headers="headers_ccf"
            :items="items_ccf"
            :search="search_ccf"
            :item-class="colorTablaCCF"
            :show-select="tipo_doc_fiscal.cod_doc_fiscal == 'NDB'"
            item-key="id_factura"
            single-select
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Facturas</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search_ccf"
                  label="Buscar"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
                <v-spacer></v-spacer>
              </v-toolbar>
            </template>

            <template
              v-slot:[`item.data-table-select`]="{ item, isSelected, select }"
            >
              <v-simple-checkbox
                :value="isSelected"
                :disabled="fechaLimiteDebitoCredito(item)"
                @input="select($event)"
              ></v-simple-checkbox>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-button @click="testCheck(item)"> Edit </v-button>
            </template>

            <template v-slot:[`item.action_add_servicio`]="{ item }">
              <v-btn
                v-if="tipo_doc_fiscal.cod_doc_fiscal == 'NCR'"
                icon
                @click="listarServiciosDocNCR(item)"
                elevation="3"
                color="black"
              >
                <v-icon>fa-solid fa-check</v-icon>
              </v-btn>
            </template>

            <template v-slot:[`item.fecha`]="{ item }">
              <span>{{ formatoFecha(item.fecha) }}</span>
            </template>

            <template v-slot:[`item.gravadas`]="{ item }">
              <span> $ {{ item.gravadas.toFixed(2) }}</span>
            </template>

            <template v-slot:[`item.iva`]="{ item }">
              <span> $ {{ item.iva.toFixed(2) }}</span>
            </template>

            <template v-slot:[`item.total_pagar`]="{ item }">
              <span> $ {{ item.total_pagar.toFixed(2) }}</span>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog_tabla_CCF = false">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_tabla_detalles_fac" width="1500" persistent>
      <v-card>
        <v-data-table
          v-model="items_productos"
          :headers="headers_detalles_fac"
          :items="items_detalles_fac"
          :search="search_detalles_fac"
          item-key="id_detalle_factura"
          show-select
          :item-class="colorTablaDetalles"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Detalles</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search_detalles_fac"
                label="Buscar"
                outlined
                dense
                hide-details
              ></v-text-field>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>

          <template v-slot:[`item.precio`]="{ item }">
            <span> $ {{ item.precio.toFixed(2) }}</span>
          </template>

          <template v-slot:[`item.descuento`]="{ item }">
            <span> % {{ item.descuento.toFixed(2) }}</span>
          </template>

          <template v-slot:[`item.total`]="{ item }">
            <span> $ {{ item.total.toFixed(2) }}</span>
          </template>
        </v-data-table>
        <v-card-actions>
          <v-btn color="primary" @click="agregarServiciosDocNCR()"
            >Aplicar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_confirmar_anular" width="500">
      <v-card>
        <v-card-title>¿Esta seguro de anular el documento?</v-card-title>
        <v-card-actions>
          <v-btn color="red" @click="anularFactura()">Confirmar</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="dialog_confirmar_anular = !dialog_confirmar_anular"
            >Cancelar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_preview_report" fullscreen persistent>
      <v-toolbar flat dense>
        <v-toolbar-title> DOCUMENTO </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-icon @click="dialog_preview_report = !dialog_preview_report"
          >far fa-times-circle</v-icon
        >
      </v-toolbar>
      <iframe :src="base64_doc" id="myPDF" frameborder="0" />
    </v-dialog>

    <v-dialog v-model="dialog_eliminar_factura" width="500">
      <v-card>
        <v-card-title
          >¿Esta seguro de eliminar la factura <br />
          {{ numero_control }}?</v-card-title
        >
        <v-card-actions>
          <v-btn color="red" dark @click="confirmarEliminarFactura()"
            >Eliminar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn text @click="dialog_eliminar_factura = false">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar"
      timeout="-1"
      color="blue"
      multi-line
      elevation="24"
      outlined
      top
      right
    >
      <v-icon>{{ icon_snackbar }}</v-icon>
      {{ snackbar_text }}

      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="snackbar = false">
          <v-icon>fa-regular fa-circle-xmark</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import settings from "@/settings/settings";
import axios from "axios";
import Papa from "papaparse";

export default {
  data: () => ({
    headers_csv: [
      { text: "Número Control", value: "encabezado.numero_control" },
      { text: "Fecha", value: "encabezado.fecha" },
      { text: "", value: "encabezado.cod_doc_fiscal" },
      { text: "Cliente", value: "encabezado.Customer" },
      { text: "Total", value: "encabezado.total_pagar" },
    ],
    items_csv: [],
    flag_csv: "no",
    data_file_input: [],
    result_data_facturas: [],
    ////////////////////////////////////////////////////////////////////
    ver_tipo_docs: "FEX",
    snackbar: false,
    snackbar_text: "Mensaje",
    icon_snackbar: "fa-solid fa-circle-info",
    empresa: "OTROS",
    search: "",
    dialog: false,
    dialog_clientes: false,
    headers: [
      { text: "Número Control", value: "numero_control" },
      { text: "Fecha", value: "fecha" },
      { text: "Tipo Documento", value: "cod_doc_fiscal" },
      { text: "Cliente", value: "cliente_nombre" },
      { text: "Total", value: "total_pagar" },
      { text: "Estatus", value: "estatus", align: "center" },
      {
        text: "Enviar DTE",
        value: "action_send_dte",
        align: "center",
        sortable: false,
      },
      {
        text: "Ver previa",
        value: "action_gen_preview",
        align: "center",
        sortable: false,
      },
      {
        text: "Ver DTE",
        value: "action_view_dte",
        align: "center",
        sortable: false,
      },
      {
        text: "Editar",
        value: "action_edit",
        align: "center",
        sortable: false,
      },
    ],
    items: [],
    id_factura: 0,
    id_empresa: 0,
    id_tipo_doc_fiscal: 0,
    dialog_eliminar_servicio: false,
    extingido_cautelado: false,
    codigo_bien: "",
    //CLIENTES
    id_cliente: 0,
    headers_clientes: [
      { text: "Cliente", value: "codigo", sorteable: false },
      { text: "Nombre", value: "nombre", sorteable: false },
      { text: "Registro", value: "registro", sorteable: false },
      {
        text: "Identificador",
        value: "identificador_nombre",
        sorteable: false,
      },
      { text: "Teléfono", value: "telefono", sorteable: false },
      { text: "Tipo", value: "tipo_persona", sorteable: false },
    ],
    items_clientes: [],
    cliente_exento: false,
    cliente_retencion: false,
    cliente_percepcion: false,
    cliente_tamaño: "",
    cliente_extranjero: false,
    //DTE-00-00000000-000000000000000
    numero_control: "DTE-00-00000000-000000000000000",
    codigo_generacion: "",
    fecha_hora: "",
    sello_recepcion: "",
    fecha_recepcion: "",
    direccion: "",
    incoterms: "",
    pais_origen: "",
    items_pais_origen: ["El Salvador", "India", "Estados Unidos"],
    peso_bruto: "",
    unidad_medida: "",
    items_unidad_medida: ["LBS", "KGS"],
    estilo: "",
    items_consignado: [],
    items_id_incoterms: [],
    items_id_clintes_consignados: [],
    cliente: "",
    nombre: "",
    registro: "",
    identifiador: "",
    telefono: "",
    tipo: "",
    num_documento: "",
    consignado_a: "",
    tipo_doc_fiscal: "",
    items_tipo_doc_fiscales: [],
    tipo_pago: "",
    items_tipo_pago: ["CONTADO", "CREDITO"],
    contador_tipo_doc_fiscal: 0,
    sello_invalidacion: "",
    dialog_eliminar_factura: false,
    loading_eliminar_factura: false,
    //SUCURSAL
    sucursal: "",
    items_sucursales: [],
    //IDENTIFICADORES
    items_identificador: [],
    //TABLA PRODUCTO
    headers_productos: [
      { text: "Estilo", value: "estilo" },
      { text: "Cantidad", value: "cantidad" },
      { text: "Unidad Medida", value: "unidad_medida" },
      { text: "Descripción", value: "descripcion" },
      { text: "Descripción 2", value: "descripcion2" },
      { text: "Precio Unitario", value: "precio" },
      { text: "Total", value: "total" },
    ],
    items_productos: [],
    cantidad: 1,
    producto: "",
    descripcion: "",
    descripcion2: "",
    pais: "",
    peso: "",
    codigo_aduana: "",
    precio: 0,
    descuento_prod: 0,
    monto_descuento: 0,
    total: 0,
    exento_prod: false,
    no_sujeto_prod: false,
    id_detalle_factura: 0,
    cont_prod: 0,
    id: 0,
    item_servicio_eliminar: "",
    //CALCULO DE VALORES
    otros_cargos: 0.0,
    anticipo: 0.0,
    sub_total: 0.0,
    descuento: 0.0,
    gravadas: 0.0,
    iva: 0.0,
    exentas: 0.0,
    no_sujetas: 0.0,
    retencion: 0.0,
    percepcion: 0.0,
    total_operacion: 0.0,
    total_pagar: 0.0,
    flag_descuento: false,
    flag_new_edit: 1,
    //TABLA CCF
    dialog_tabla_CCF: false,
    search_ccf: "",
    headers_ccf: [
      { text: "Servicios", value: "action_add_servicio" },
      { text: "Numero de control", value: "numero_control" },
      { text: "Fecha", value: "fecha" },
      { text: "Tipo documento", value: "tipo_documento_nombre" },
      { text: "Tipo pago", value: "tipo_pago" },
      { text: "Código cliente", value: "cliente_codigo" },
      { text: "Cliente", value: "cliente_nombre" },
      { text: "Gravadas", value: "gravadas" },
      { text: "IVA", value: "iva" },
      { text: "Total", value: "total_pagar" },
    ],
    items_ccf: [],
    //TABLA DETALLES FACTURA
    dialog_tabla_detalles_fac: false,
    search_detalles_fac: "",
    headers_detalles_fac: [
      { text: "Cantidad", value: "cantidad" },
      { text: "Descripción", value: "descripcion" },
      { text: "Precio", value: "precio" },
      { text: "%Descuento", value: "descuento" },
      { text: "Total", value: "total" },
    ],
    items_detalles_fac: [],
    //DOCUMENTO NCR
    items_tipo_doc_ncr_ndb: [],
    lista_nrc_ndb_guardados: [],
    //SERVICIOS
    dialog_servicios: false,
    headers_servicios: [
      { text: "Producto", value: "cod_servicio" },
      { text: "Descripción", value: "nom_servicio" },
      { text: "Precio", value: "precio" },
    ],
    items_servicios: [],
    flag_servicio: true,
    dialog_confirmar_anular: false,
    dialog_preview_report: false,
    base64_doc: "",
    loading_facturas: false,
  }),
  created() {
    this.ver_tipo_docs = localStorage.getItem("feSwisstexTipoDoc");

    //Especial para FEX
    if (this.flag_csv == "si") {
      this.obtenerCorrelativoTipoDocCSV(12);
    }
    //console.log(this.$store.state.usuario);
    //console.log(this.$store.state.emp);
  },
  computed: {
    tiene_empresa() {
      if (this.$store.state.emp) {
        this.listarFacturas();
        this.listarSucursales();
        this.listarClientes();
        this.listarServicios();
        this.listarIncoterms();
        this.listarConsignados();
        return true;
      } else {
        return false;
      }
    },
    calculoSubTotal() {
      this.sub_total = 0;
      this.sub_total = (
        Number(this.gravadas) +
        Number(this.exentas) +
        Number(this.no_sujetas)
      ).toFixed(2);
      return this.sub_total;
    },
    calculoCantidad() {
      this.total = (Number(this.cantidad) * Number(this.precio)).toFixed(2);
    },
    calculoTotalOperacion() {
      if (this.tipo_doc_fiscal.cod_doc_fiscal == "NCR") {
        this.total_operacion = (
          (Number(this.gravadas) + Number(this.iva)) - Number(this.retencion)
        ).toFixed(2);
        return this.total_operacion;
      }
      if (this.tipo_doc_fiscal.cod_doc_fiscal == "CCF" || this.tipo_doc_fiscal.cod_doc_fiscal == "FAC") {
        this.total_operacion = (
          (Number(this.gravadas) + Number(this.iva))
        ).toFixed(2);
        return this.total_operacion;
      }
      
      this.total_operacion = (
        Number(this.gravadas) +
        Number(this.iva) -
        Number(this.retencion) +
        Number(this.percepcion) +
        Number(this.exentas) +
        Number(this.no_sujetas)
      ).toFixed(2);
      return this.total_operacion;
    },
    calculoTotalPagar() {
      this.total_pagar = (
        Number(this.gravadas) +
        Number(this.iva) -
        Number(this.retencion) +
        Number(this.percepcion) +
        Number(this.exentas) +
        Number(this.no_sujetas) +
        Number(this.otros_cargos) -
        Number(this.anticipo)
      ).toFixed(2);
      return this.total_pagar;
    },
  },
  methods: {
    reenvioCorreo() {
      axios
        .post("api/ServicioRecepciones/EnviarCorreos", {
          id_emp: 49,
          cod_emp: this.$store.state.emp.cod_empresa,
          nom_base: "",
          codFactura: "E02C5769-782D-406C-9478-817ABC72F85D",
          tipoDte: this.tipo_doc_fiscal.id_tipo_doc_fiscal,
          origen: "SQLSERVER",
          correos: ["john.linares@devco.sv"],
        })
        .then((response) => {
          //console.log("Correo eviado", response.data);
        })
        .catch((error) => {
          //console.log("No se envio correo", error);
        });
    },
    localStorageTipoDoc() {
      localStorage.setItem("feSwisstexTipoDoc", this.ver_tipo_docs);
    },
    facturasCSV() {
      this.flag_csv = "si";
    },
    async procesarCSV() {
      if (this.data_file_input.length > 0) {
        this.result_data_facturas = [];
        var flag_index = 0;
        var cont = 0;
        for (var i = 0; i < this.data_file_input.length; i++) {
          var guid = await this.obtenerGuidCSV();
          var num_control = (await this.obtenerCorrelativoTipoDocCSV(12)) + i;
          this.contador_tipo_doc_fiscal = num_control;
          var formato_ceros = "000000000000000".substring(
            0,
            15 - num_control.toString().length
          );
          Papa.parse(this.data_file_input[i], {
            header: false,
            dynamicTyping: true,
            skipEmptyLines: true,
            complete: (results) => {
              this.obtenerClienteCSV(results.data[0][0]).then((res) => {
                results.data.forEach((item) => {
                  if (item[0] != null) {
                    flag_index = cont++;
                    this.result_data_facturas.push({
                      encabezado: {
                        id_tipo_doc_fiscal: 12,
                        id_cliente: res[0].id_cliente,
                        id_empresa: this.$store.state.emp.id_empresa,
                        id_sucursal: 33,
                        numero_control:
                          "DTE-11-00000000-" + formato_ceros + num_control,
                        codigo_generacion: guid,
                        fecha: settings.metodos.formatoFechaHora(new Date()),
                        sello_recepcion: "",
                        fecha_recepcion: "",
                        anticipo: 0,
                        exentas: 0,
                        no_sujetas: 0,
                        otros_cargos: 0,
                        percepcion: 0,
                        retencion: 0,
                        iva: 0,
                        sub_total: 0,
                        cod_cliente: item[0],
                        Customer: item[1],
                        NIT: item[2],
                        LineofBusiness: item[3],
                        Country: item[4],
                        Address: item[5],
                        ciudad: item[6],
                        estado: item[7],
                        Phonenumber: item[8],
                        correo1: item[9],
                        correo2: item[10],
                        correo3: item[11],
                        consignado_a: item[12],
                        consignado_a_direccion: item[13],
                        descuento: item[14] != null ? item[14] : 0,
                        seguro: item[15] != null ? item[15] : 0,
                        transporte: item[16] != null ? item[16] : 0,
                        total_pagar: item[17],
                        tipo_pago: item[18],
                        incoterms: item[19],
                        comentario: item[20],
                        referencia: item[21],
                        pais_origen: item[22],
                        total_pounds: item[23] != null ? item[23] : 0,
                        raw_material: item[24] != null ? item[24] : 0,
                        sample_charge: 0,
                        otros: 0,
                        efficiency_surcharge: 0,
                        light_weight_charge_money: 0,
                        bags_per_piece: 0,
                        gross_weight: 0,                        
                        added_value: 0,
                        customs_total: 0,
                        gravadas: item[17] != null ? item[17] : 0,
                        total_operacion: item[17] != null ? item[17] : 0,
                        estatus: "GUARDADO",
                        es_anulado: false,
                      },
                      detalles: [
                        {
                          cod_servicio: "01",
                          descuento: 0,
                          estilo: item[25],
                          cantidad: item[26] != null ? item[26] : 0,
                          unidad_medida: item[27],
                          descripcion: item[28],
                          descripcion2: item[29],
                          precio: item[30] != null ? item[30] : 0,
                          total: item[31] != null ? item[31] : 0,
                        },
                      ],
                    });
                  } else {
                    this.result_data_facturas[flag_index].detalles.push({
                      cod_servicio: "01",
                      descuento: 0,
                      estilo: item[25],
                      cantidad: item[26] != null ? item[26] : 0,
                      unidad_medida: item[27],
                      descripcion: item[28],
                      descripcion2: item[29],
                      precio: item[30] != null ? item[30] : 0,
                      total: item[31] != null ? item[31] : 0,
                    });
                  }
                });
              });
              //console.log(this.result_data_facturas);
            },
          });
        }
        this.items_csv = this.result_data_facturas;
      }
    },
    guardarCSV() {
      this.result_data_facturas.forEach((element) => {
        axios
          .post("api/Facturas/CrearCSV", {
            encabezado: element.encabezado,
            detalle: element.detalles,
          })
          .then(() => {
            this.incrementarCorrelativoTipoDocCSV();
          })
          .catch((e) => {
            //console.log("ERROR", e);
          });
      });
      this.flag_csv = "no";
      this.data_file_input = [];
      this.items_csv = [];
      this.file = [];
      this.listarFacturas();
    },
    enviarDTE(item) {
      this.loading_facturas = true;
      axios
        .post("api/ServicioRecepciones/FirmarDocumento2", {
          cod_emp: this.$store.state.emp.id_empresa,
          tipoDte: item.cod_doc_fiscal,
          idFactura: item.id_factura,
          ambiente: "00",
        })
        .then(() => {
          this.loading_facturas = false;
          this.snackbar_text = "Solicitud enviada";
          this.snackbar = true;
          this.listarFacturas();
        })
        .catch(() => {
          this.loading_facturas = false;
          this.snackbar_text = "Error de solicitud";
          this.snackbar = true;
        });
    },
    vistaPreviaPdf(item) {
      //console.log(item);
      this.loading_facturas = true;

      axios
        .post("api/ServicioRecepciones/VistaPreviaDTE2", {
          cod_emp: this.$store.state.emp.id_empresa,
          tipoDte: item.cod_doc_fiscal,
          idFactura: item.id_factura,
          ambiente: "00",
          es_previo: true,
        })
        .then((response) => {
          //console.log(response.data);
          this.loading_facturas = false;
          this.base64_doc = "data:application/pdf;base64," + response.data;
          this.dialog_preview_report = true;
        })
        .catch(() => {
          this.snackbar_text = "No se pudo generar el reporte";
          this.snackbar = true;
          this.loading_facturas = false;
        });
    },
    vistaPreviaPdfSello(item) {
      this.loading_facturas = true;
      axios
        .post("api/ServicioRecepciones/DescargarFacturaWeb", {
          cod_emp: this.$store.state.emp.id_empresa,
          tipoDte: item.cod_doc_fiscal,
          idFactura: item.id_factura,
          ambiente: "00",
          es_previo: false,
        })
        .then((response) => {
          //console.log(response.data);
          this.loading_facturas = false;
          this.base64_doc = "data:application/pdf;base64," + response.data;
          this.dialog_preview_report = true;
        })
        .catch(() => {
          this.snackbar_text = "No se pudo generar el reporte";
          this.snackbar = true;
          this.loading_facturas = false;
        });
    },
    listarFacturas() {
      this.loading_facturas = true;
      axios
        .get(
          "api/Facturas/ListarFeSwisstex/" +
            this.$store.state.emp.id_empresa +
            "/" +
            this.ver_tipo_docs
        )
        .then((response) => {
          //console.log(response.data);
          this.items = response.data;
          this.loading_facturas = false;
        })
        .catch(() => {
          this.loading_facturas = false;
        });
    },
    listarSucursales() {
      axios
        .get("api/Sucursales/Listar/" + this.$store.state.emp.id_empresa)
        .then((response) => {
          this.items_sucursales = response.data;
        });
    },
    listarTipoDocs() {
      if (this.sucursal) {
        axios
          .get("api/ConfiFacturas/Listar/" + this.sucursal)
          .then((response) => {
            this.items_tipo_doc_fiscales = response.data;

            if (this.items_productos.length > 0) {
              this.limpiarCalculosCabecera();

              for (var i = 0; i < this.items_productos.length; i++) {
                //CALCULOS DE DESCUENTO
                this.sumarDescuentos();

                //CALCULOS DE EXENTO Y NO SUJETO
                if (this.cliente_exento) {
                  this.calculoExento(this.items_productos[i].total);
                  this.limpiarCamposProductos();
                  continue;
                }

                if (this.items_productos[i].exento) {
                  this.calculoExento(this.items_productos[i].total);
                  this.limpiarCamposProductos();
                  continue;
                }
                if (this.items_productos[i].no_sujeto) {
                  this.calculoNoSujeto();
                  this.limpiarCamposProductos();
                  continue;
                }

                if (this.cliente_retencion) {
                  if (this.total >= 100) {
                    this.retencion = (
                      Number(this.total) * Number(0.01)
                    ).toFixed(2);
                  }
                }
                if (this.cliente_percepcion) {
                  if (this.total >= 100) {
                    this.percepcion = (
                      Number(this.total) * Number(0.01)
                    ).toFixed(2);
                  }
                }

                this.sumarSubTotales(this.items_productos[i].total);
                this.calculoGravadas(this.sub_total, this.descuento);

                if (
                  this.tipo_doc_fiscal.cod_doc_fiscal == "CCF" ||
                  this.tipo_doc_fiscal.cod_doc_fiscal == "NCR" ||
                  this.tipo_doc_fiscal.cod_doc_fiscal == "NDB"
                ) {
                  this.calculoIva(this.gravadas);
                }
              }
            }
          });
      }
    },
    listarClientes() {
      axios
        .get("api/Clientes/ListarTodos/" + this.$store.state.emp.id_empresa)
        .then((response) => {
          this.items_clientes = response.data;
        });
    },
    nuevaFactura() {
      this.fecha_hora = settings.metodos.formatoFechaHora(new Date());
      this.obtenerGuid();
      this.flag_new_edit = 1;
      this.dialog = true;
    },
    async obtenerGuidCSV() {
      return await (
        await axios.get("api/Facturas/GenerarGuid")
      ).data;
    },
    obtenerGuid() {
      axios.get("api/Facturas/GenerarGuid").then((response) => {
        this.codigo_generacion = response.data;
      });
    },
    asignarProducto() {
      axios
        .get(
          "api/Servicios/ObtenerServicioPorCodigo/" +
            this.producto +
            "/" +
            this.$store.state.emp.id_empresa
        )
        .then((response) => {
          if (
            this.tipo_doc_fiscal.cod_doc_fiscal == "FAC" ||
            this.tipo_doc_fiscal.cod_doc_fiscal == "FEX"
          ) {
            if (response.data.exento || response.data.no_sujeto) {
              this.precio = (
                Number(response.data.precio) / Number(1.13)
              ).toFixed(4);
            } else {
              this.precio = response.data.precio.toFixed(4);
            }
          } else {
            this.precio = (Number(response.data.precio) / Number(1.13)).toFixed(
              4
            );
          }
          this.flag_servicio = response.data == "" ? true : false;
          this.descripcion = response.data.nom_servicio;
          this.total = (Number(this.precio) * Number(this.cantidad)).toFixed(2);
          this.exento_prod = response.data.exento;
          this.no_sujeto_prod = response.data.no_sujeto;
        });
    },
    modificarPrecio() {
      this.precio = Number(this.precio).toFixed(4);
      this.total = Number(this.precio).toFixed(2);
    },
    calculoDescuento() {
      this.monto_descuento = 0;
      this.monto_descuento =
        Number(this.cantidad) *
        Number(this.precio) *
        (Number(this.descuento_prod) / 100);
      this.total = (Number(this.total) - Number(this.monto_descuento)).toFixed(
        2
      );
    },
    setCliente() {
      this.id_cliente = this.cliente.id_cliente;
      this.nombre = this.cliente.nombre;
      this.registro = this.cliente.registro;
      this.identifiador = this.cliente.identificador_nombre;
      this.telefono = this.cliente.telefono;
      this.tipo = this.cliente.tipo_persona;
      this.cliente_exento = this.cliente.exento;
      this.cliente_retencion = this.cliente.con_retencion;
      this.cliente_percepcion = this.cliente.con_percepcion;
      this.cliente_tamaño = this.cliente.tamaño;
      this.cliente_extranjero = this.cliente.extranjero;
      this.num_documento = this.cliente.documento;

      this.dialog_clientes = false;
    },
    obtenerClienteCSV(cod_cliente) {
      var id = axios
        .get(
          "api/Clientes/ListarXcod/" +
            this.$store.state.emp.id_empresa +
            "/" +
            cod_cliente
        )
        .then((res) => {
          //console.log(res.data);
          return res.data;
        });
      return id;
    },
    listarServicios() {
      axios
        .get("api/Servicios/Listar/" + this.$store.state.emp.id_empresa)
        .then((response) => {
          //console.log(response.data);
          this.items_servicios = response.data;
        });
    },
    listarIncoterms() {
      axios.get("api/Incoterms/Listar").then((response) => {
        //console.log(response.data);
        this.items_id_incoterms = response.data;
      });
    },
    listarConsignados() {
      axios.get("api/Consignatarios/Listar").then((response) => {
        //console.log(response.data);
        this.items_id_clintes_consignados = response.data;
      });
    },
    setConsignado() {
      const consignado = this.items_id_clintes_consignados.find(
        (cons) => cons.consignatarios === this.consignado_a
      );
      if (consignado) {
        this.direccion = consignado.direccion;
      }
    },
    setServicio(event, { item }) {
      if (
        this.tipo_doc_fiscal.cod_doc_fiscal == "FAC" ||
        this.tipo_doc_fiscal.cod_doc_fiscal == "FEX"
      ) {
        if (item.exento || item.no_sujeto) {
          this.precio = (Number(item.precio) / Number(1.13)).toFixed(4);
        } else {
          this.precio = Number(item.precio).toFixed(4);
        }
      } else {
        this.precio = (Number(item.precio) / Number(1.13)).toFixed(4);
      }

      this.producto = item.cod_servicio;
      this.descripcion = item.nom_servicio;
      this.total = (Number(this.precio) * Number(this.cantidad)).toFixed(2);
      this.exento_prod = item.exento;
      this.no_sujeto_prod = item.no_sujeto;

      this.dialog_servicios = false;
    },
    agregarProducto() {
      if (!this.cliente || !this.tipo_doc_fiscal) {
        this.snackbar_text =
          "Debe de seleccionar el tipo de documento fiscal y el cliente";
        this.snackbar = true;
        return;
      }
      if (this.tipo_doc_fiscal.cod_doc_fiscal == "FEX") {
        if (this.cliente_extranjero == false) {
          this.snackbar_text = "El cliente no es extranjero";
          this.snackbar = true;
          return;
        }
      }
      if (this.total == 0) {
        return;
      }

      this.sumarDescuentos();

      this.items_productos.push({
        id_detalle_factura: -1,
        cantidad: this.cantidad,
        estilo: this.estilo,
        unidad_medida: this.unidad_medida,
        descripcion: this.descripcion,
        descripcion2: this.descripcion2,
        precio: this.precio,
        descuento: this.descuento_prod,
        total: this.total,
        exento: this.tipo_doc_fiscal.cod_doc_fiscal == "NRM" ? 1 : 0,
        no_sujeto: this.no_sujeto_prod,
        cod_servicio: "01",
      });

      if (this.tipo_doc_fiscal.cod_doc_fiscal == "NRM") {
        this.items_productos.push({
          id_detalle_factura: -1,
          cantidad: 0,
          estilo: "",
          unidad_medida: "",
          descripcion: this.pais,
          descripcion2: "",
          precio: 0,
          descuento: 0,
          total: 0,
          exento: 0,
          no_sujeto: 0,
        });

        this.items_productos.push({
          id_detalle_factura: -1,
          cantidad: 0,
          estilo: "",
          unidad_medida: "",
          descripcion: this.peso,
          descripcion2: "",
          precio: 0,
          descuento: 0,
          total: 0,
          exento: 0,
          no_sujeto: 0,
        });

        this.items_productos.push({
          id_detalle_factura: -1,
          cantidad: 0,
          estilo: "",
          unidad_medida: "",
          descripcion: this.codigo_aduana,
          descripcion2: "",
          precio: 0,
          descuento: 0,
          total: 0,
          exento: 0,
          no_sujeto: 0,
        });
      }

      if (this.cliente_exento) {
        this.calculoExento(this.total);
        this.limpiarCamposProductos();
        return;
      }

      if (this.tipo_doc_fiscal.cod_doc_fiscal == "NRM") {
        this.calculoExento(this.total);
        this.limpiarCamposProductos();
      } else {
        if (this.exento_prod) {
          this.calculoExento(this.total);
          this.limpiarCamposProductos();
          return;
        }
      }
      if (this.no_sujeto_prod) {
        this.calculoNoSujeto();
        this.limpiarCamposProductos();
        return;
      }

      if (this.cliente_retencion) {
        if (this.total >= 100) {
          this.retencion = (Number(this.total) * Number(0.01)).toFixed(2);
        }
      }

      if (this.cliente_percepcion) {
        if (this.total >= 100) {
          this.percepcion = (Number(this.total) * Number(0.01)).toFixed(2);
        }
      }

      this.sumarSubTotales(this.total);
      //this.calculoGravadas(this.sub_total, this.descuento);
      if (this.tipo_doc_fiscal.cod_doc_fiscal != "NRM") {
        this.calculoGravadas(this.sub_total, this.descuento);
      }

      if (
        this.tipo_doc_fiscal.cod_doc_fiscal == "CCF" ||
        this.tipo_doc_fiscal.cod_doc_fiscal == "NCR" ||
        this.tipo_doc_fiscal.cod_doc_fiscal == "NDB"
      ) {
        this.calculoIva(this.gravadas);
      }

      this.limpiarCamposProductos();
    },
    llamarCalculo() {
      for (var i = 0; i < this.items_productos.length; i++) {
        this.limpiarCalculosCabecera();

        for (var i = 0; i < this.items_productos.length; i++) {
          //CALCULOS DE DESCUENTO
          this.sumarDescuentos();

          //CALCULOS DE EXENTO Y NO SUJETO
          if (this.tipo_doc_fiscal.cod_doc_fiscal == "NRM") {
            this.calculoExento(this.items_productos[i].total);
            continue;
          }

          if (this.cliente_exento) {
            this.calculoExento(this.items_productos[i].total);
            this.limpiarCamposProductos();
            continue;
          }

          if (this.items_productos[i].exento) {
            this.calculoExento(this.items_productos[i].total);
            this.limpiarCamposProductos();
            continue;
          }
          if (this.items_productos[i].no_sujeto) {
            this.calculoNoSujeto();
            this.limpiarCamposProductos();
            continue;
          }

          if (this.tipo_doc_fiscal.cod_doc_fiscal == "FAC") {
            if (this.cliente_retencion) {
              if (this.items_productos[i].total >= 100) {
                var sin_iva = (this.items_productos[i].total / 1.13).toFixed(2);
                this.retencion = (
                  Number(this.retencion) +
                  sin_iva * 0.01
                ).toFixed(2);
              }
            }
          } else {
            if (this.cliente_retencion) {
              if (this.items_productos[i].total >= 100) {
                this.retencion = (
                  Number(this.retencion) +
                  Number(this.items_productos[i].total) * Number(0.01)
                ).toFixed(2);
              }
            }
          }

          if (this.cliente_percepcion) {
            if (this.total >= 100) {
              this.percepcion =
                Number(this.percepcion) +
                (Number(this.items_productos[i].total) * Number(0.01)).toFixed(
                  2
                );
            }
          }

          this.sumarSubTotales(this.items_productos[i].total);
          if (this.tipo_doc_fiscal.cod_doc_fiscal != "NRM") {
            this.calculoGravadas(this.sub_total, this.descuento);
          }

          if (
            this.tipo_doc_fiscal.cod_doc_fiscal == "CCF" ||
            this.tipo_doc_fiscal.cod_doc_fiscal == "NCR" ||
            this.tipo_doc_fiscal.cod_doc_fiscal == "NDB" ||
            this.tipo_doc_fiscal.cod_doc_fiscal == "NRM"
          ) {
            this.calculoIva(this.gravadas);
          }
        }
      }
    },
    editarDetalleServicio(item) {
      var temp_sub_total = 0;
      var temp_gravadas = 0;
      this.llamarCalculo();
      /*this.sub_total = 0;
      this.gravadas = 0;  
      for (var i = 0; i < this.items_productos.length; i++) {
        temp_sub_total = parseFloat(
          Number(temp_sub_total) + Number(this.items_productos[i].total)
        ).toFixed(2);
        if (this.tipo_doc_fiscal.tipo_documento_cod != "NRM") {
          temp_gravadas = parseFloat(
            Number(temp_gravadas) + Number(this.items_productos[i].total)
          ).toFixed(2);
        }
        this.calculoExento(this.total);
        this.sub_total = temp_sub_total;
        this.gravadas = temp_gravadas;
      }*/
    },
    sumarSubTotales(v_total) {
      this.sub_total = parseFloat(
        Number(this.sub_total) + Number(v_total)
      ).toFixed(2);
    },
    sumarDescuentos() {
      if (this.descuento_prod > 0) {
        this.flag_descuento = true;
      }
      this.descuento = parseFloat(
        Number(this.descuento) + Number(this.monto_descuento)
      ).toFixed(2);
    },
    calculoGravadas(v_sub_total, v_descuento) {
      this.gravadas = parseFloat(
        Number(v_sub_total) - Number(v_descuento)
      ).toFixed(2);
    },
    calculoIva(v_gravadas) {
      this.iva = parseFloat(Number(v_gravadas) * Number(0.13)).toFixed(2);
    },
    calculoExento(v_total) {
      this.exentas = (Number(this.exentas) + Number(v_total)).toFixed(2);
    },
    calculoNoSujeto() {
      this.no_sujetas = (Number(this.no_sujetas) + Number(this.total)).toFixed(
        2
      );
    },
    crearfactura() {
      //console.log(this.items_productos);
      var items_detalles_factura_ncr = [];
      var items_facturas_ndb = [];

      if (this.total_pagar == 0) {
        this.snackbar_text = "El total no debe de ir a cero";
        this.snackbar = true;
        return;
      }

      for (var i = 0; i < this.items_productos.length; i++) {
        if (this.tipo_doc_fiscal.cod_doc_fiscal == "NCR") {
          items_detalles_factura_ncr.push({
            cantidad: this.items_productos[i].cantidad,
            cod_servicio: this.items_productos[i].cod_servicio,
            descripcion: this.descripcion,
            descripcion2: this.items_productos[i].descripcion2,
            estilo: this.items_productos[i].estilo,
            unidad_medida: this.items_productos[i].unidad_medida,
            pais: this.items_productos[i].pais,
            peso: this.items_productos[i].peso,
            codigo_aduana: this.items_productos[i].codigo_aduana,
            precio: this.items_productos[i].precio,
            descuento: this.items_productos[i].descuento,
            total: this.items_productos[i].total,
            exento: this.items_productos[i].exento,
            no_sujeto: this.items_productos[i].no_sujeto,
            id_detalle_factura_ncr: this.items_productos[i].id_detalle_factura,
          });

          var index = this.items_tipo_doc_ncr_ndb.findIndex(
            (item) => item.id_factura == this.items_productos[i].id_factura
          );
          if (index == -1) {
            this.items_tipo_doc_ncr_ndb.push({
              id_empresa: this.$store.state.emp.id_empresa,
              id_factura_relacionado: this.items_productos[i].id_factura,
            });
          }
        } else {
          items_detalles_factura_ncr.push(this.items_productos[i]);
        }
      }

      for (var i = 0; i < this.items_tipo_doc_ncr_ndb.length; i++) {
        items_facturas_ndb.push({
          id_empresa: this.$store.state.emp.id_empresa,
          id_factura_relacionado:
            this.items_tipo_doc_ncr_ndb[i].id_factura_relacionado,
        });
      }

      axios
        .post("api/Facturas/Crear", {
          id_tipo_doc_fiscal: this.tipo_doc_fiscal.id_tipo_doc_fiscal,
          id_cliente: this.id_cliente,
          id_empresa: this.$store.state.emp.id_empresa,
          id_sucursal: this.sucursal,
          numero_control: this.numero_control,
          codigo_generacion: this.codigo_generacion,
          fecha: this.fecha_hora,
          sello_recepcion: this.sello_recepcion,
          fecha_recepcion: this.fecha_recepcion,
          tipo_pago: this.tipo_pago,
          otros_cargos: this.otros_cargos,
          anticipo: this.anticipo,
          sub_total: this.sub_total,
          descuento: this.descuento,
          gravadas: this.gravadas,
          iva: this.iva,
          exentas: this.exentas,
          no_sujetas: this.no_sujetas,
          retencion: this.retencion,
          percepcion: this.percepcion,
          total_operacion: this.total_operacion,
          total_pagar: this.total_pagar,
          estatus: "GUARDADO",
          ciudad: "",
          estado: "",
          Phonenumber: "",
          correo1: "",
          correo2: "",
          correo3: "",
          consignado_a: this.consignado_a,
          consignado_a_direccion: this.direccion,
          descuento: 0.0,
          seguro: 0.0,
          transporte: 0.0,
          incoterms: this.incoterms,
          comentario: "",
          referencia: "",
          sample_charge: 0.0,
          otros: 0.0,
          efficiency_surcharge: 0.0,
          light_weight_charge_money: 0.0,
          bags_per_piece: 0.0,
          pais_origen: this.pais_origen,
          total_pounds: 0.0,
          gross_weight: this.peso_bruto,
          raw_material: 0.0,
          added_value: 0.0,
          customs_total: 0.0,
          codigo_bien: this.codigo_bien,
          extingido_cautelado: this.extingido_cautelado,
          detalleFactura: items_detalles_factura_ncr,
          detalleNDB_NCR: items_facturas_ndb,
        })
        .then(() => {
          this.snackbar_text = "Registro creado con exito";
          this.snackbar = true;
          this.incrementarCorrelativoTipoDoc();
        })
        .catch(() => {
          this.snackbar_text = "Sucedio algún error";
          this.snackbar = true;
        });
    },
    editarFactura(item) {
      //console.log(item);
      this.id_factura = item.id_factura;
      this.id_tipo_doc_fiscal = item.id_tipo_doc_fiscal;
      this.tipo_doc_fiscal = item;
      this.id_cliente = item.id_cliente;
      this.id_empresa = item.id_empresa;
      this.sucursal = item.id_sucursal;
      this.numero_control = item.numero_control;
      this.codigo_generacion = item.codigo_generacion;
      this.fecha_hora = item.fecha;
      this.sello_recepcion = item.sello_recepcion;
      this.fecha_recepcion = item.fecha_recepcion;
      this.tipo_pago = item.tipo_pago;
      this.otros_cargos = item.otros_cargos;
      this.anticipo = item.anticipo;
      this.sub_total = item.sub_total;
      this.descuento = item.descuento;
      this.gravadas = item.gravadas;
      this.iva = item.iva;
      this.exentas = item.exentas;
      this.no_sujetas = item.no_sujetas;
      this.retencion = item.retencion;
      this.percepcion = item.percepcion;
      this.total_operacion = item.total_operacion;
      this.total_pagar = item.total_pagar;
      this.codigo_bien = item.codigo_bien;
      this.extingido_cautelado = item.extingido_cautelado;
      this.consignado_a = item.consignado_a;
      this.direccion = item.consignado_a_direccion;
      this.incoterms = item.incoterms;
      this.peso_bruto = item.gross_weight;
      this.pais_origen = item.pais_origen;

      this.cliente = item.cliente_codigo;
      this.nombre = item.cliente_nombre;
      this.registro = item.cliente_registro;
      this.identifiador = item.cliente_identificador;
      this.telefono = item.cliente_telefono;
      this.tipo = item.cliente_tipo;

      //this.listarTipoDocs();
      //this.listarDocSeleccionado();
      this.listarDetallesfactura();

      this.flag_new_edit = 2;
      this.dialog = true;
    },
    actualizarfactura() {
      axios
        .put("api/Facturas/Actualizar", {
          id_factura: this.id_factura,
          id_tipo_doc_fiscal: this.tipo_doc_fiscal.id_tipo_doc_fiscal,
          id_cliente: this.id_cliente,
          id_empresa: this.$store.state.emp.id_empresa,
          id_sucursal: this.sucursal,
          numero_control: this.numero_control,
          codigo_generacion: this.codigo_generacion,
          fecha: this.fecha_hora,
          sello_recepcion: this.sello_recepcion,
          fecha_recepcion: this.fecha_recepcion,
          tipo_pago: this.tipo_pago,
          otros_cargos: this.otros_cargos,
          anticipo: this.anticipo,
          sub_total: this.sub_total,
          descuento: this.descuento,
          gravadas: this.gravadas,
          iva: this.iva,
          exentas: this.exentas,
          no_sujetas: this.no_sujetas,
          retencion: this.retencion,
          percepcion: this.percepcion,
          total_operacion: this.total_operacion,
          total_pagar: this.total_pagar,
          estatus: "GUARDADO",
          ciudad: "",
          estado: "",
          Phonenumber: "",
          correo1: "",
          correo2: "",
          correo3: "",
          consignado_a: this.consignado_a,
          consignado_a_direccion: this.direccion,
          descuento: 0.0,
          seguro: 0.0,
          transporte: 0.0,
          incoterms: this.incoterms,
          comentario: "",
          referencia: "",
          sample_charge: 0.0,
          otros: 0.0,
          efficiency_surcharge: 0.0,
          light_weight_charge_money: 0.0,
          bags_per_piece: 0.0,
          pais_origen: this.pais_origen,
          total_pounds: 0.0,
          gross_weight: this.peso_bruto,
          raw_material: 0.0,
          added_value: 0.0,
          customs_total: 0.0,
          codigo_bien: "",
          extingido_cautelado: false,
          detalleFactura: this.items_productos,
        })
        .then(() => {
          this.snackbar_text = "Registro actualizado con exito";
          this.snackbar = true;
          this.cerrarDialog();
          this.listarFacturas();
        })
        .catch(() => {
          this.snackbar_text = "Sucedio algún error";
          this.snackbar = true;
        });
    },
    confirmarAnularDocumento() {
      this.dialog_confirmar_anular = true;
    },
    anularFactura() {
      axios
        .post("api/ServicioRecepciones/FirmarDocumento2", {
          cod_emp: this.$store.state.emp.id_empresa,
          tipoDte: "INV",
          idFactura: this.id_factura,
          ambiente: "00",
        })
        .then(() => {
          this.snackbar_text = "Solicitud enviada";
          this.snackbar = true;
          this.listarFacturas();
        })
        .catch(() => {
          this.snackbar_text = "Error de solicitud";
          this.snackbar = true;
        });
    },
    eliminarFactura() {
      this.dialog_eliminar_factura = true;
    },
    confirmarEliminarFactura() {
      this.loading_eliminar_factura = true;
      axios
        .delete("api/Facturas/Eliminar/" + this.id_factura)
        .then(() => {
          this.snackbar_text = "Factura eliminada exitosamente";
          this.snackbar = true;
          this.dialog_eliminar_factura = false;
          this.cerrarDialog();
          this.listarFacturas();
          this.loading_eliminar_factura = false;
        })
        .catch(() => {
          this.snackbar_text = "No se pudo eliminar la factura";
          this.snackbar = true;
          this.loading_eliminar_factura = false;
        });
    },
    listarDetallesfactura() {
      axios
        .get("api/Detallesfacturas/Listar/" + this.id_factura)
        .then((response) => {
          //console.log(response.data);
          this.items_productos = response.data;
        });
    },
    limpiarCamposProductos() {
      this.cantidad = 1;
      this.producto = "";
      this.descripcion = "";
      this.descripcion2 = "";
      this.precio = 0;
      this.estilo = "";
      this.unidad_medida = "";
      this.pais = "";
      this.peso = "";
      this.codigo_aduana = "";

      this.descuento_prod = 0;
      this.total = 0;
    },
    limpiarCalculosCabecera() {
      this.otros_cargos = 0;
      this.anticipo = 0;
      this.sub_total = 0;
      this.descuento = 0;
      this.gravadas = 0;
      this.iva = 0;
      this.exentas = 0;
      this.no_sujetas = 0;
      this.retencion = 0;
      this.percepcion = 0;
      this.total_operacion = 0;
      this.total_pagar = 0;
    },
    cerrarDialog() {
      this.dialog = false;
      this.limpiar();
    },
    limpiar() {
      this.id_tipo_doc_fiscal = 0;
      this.numero_control = "DTE-00-00000000-000000000000000";
      this.fecha_hora = "";
      this.codigo_generacion = "";
      this.sello_recepcion = "";
      this.fecha_recepcion = "";
      this.tipo_doc_fiscal = "";
      this.tipo_pago = "";
      this.sucursal = "";
      this.id_cliente = 0;
      this.cliente = "";
      this.nombre = "";
      this.registro = "";
      this.identifiador = "";
      this.telefono = "";
      this.tipo = "";
      this.direccion = "";
      this.cliente_exento = "";
      this.cliente_retencion = "";
      this.cliente_percepcion = "";
      this.cliente_tamaño = "";
      this.cantidad = 1;
      this.producto = "";
      this.descripcion = "";
      this.descripcion2 = "";
      this.estilo = "";
      this.unidad_medida = "";
      this.pais = "";
      this.peso = "";
      this.codigo_aduana = "";
      this.precio = 0;
      this.descuento = 0;
      this.total = 0;
      this.items_productos = [];
      this.otros_cargos = 0;
      this.anticipo = 0;
      this.sub_total = 0;
      this.gravadas = 0;
      this.iva = 0;
      this.exentas = 0;
      this.no_sujetas = 0;
      this.retencion = 0;
      this.percepcion = 0;
      this.total_operacion = 0;
      this.total_pagar = 0;
      this.id_detalle_factura = 0;
      this.cont_prod = 0;
      this.flag_new_edit = 1;
      this.codigo_bien = "";
      this.extingido_cautelado = false;
      this.items_tipo_doc_ncr_ndb = [];
    },
    async obtenerCorrelativoTipoDocCSV(id) {
      return await (
        await axios.get(
          "api/ConfiFacturas/ListarSeleccionado/" +
            id +
            "/" +
            this.$store.state.emp.id_empresa
        )
      ).data.correlativo;
    },
    listarDocSeleccionado() {
      var formato = "DTE-";
      var formato_ceros = "-00000000-000000000000000";
      var correlativo_length = "";
      var id = 0;

      if (this.tipo_doc_fiscal.id_tipo_doc_fiscal != undefined) {
        id = this.tipo_doc_fiscal.id_tipo_doc_fiscal;
      } else {
        id = this.id_tipo_doc_fiscal;
      }

      axios
        .get(
          "api/ConfiFacturas/ListarSeleccionado/" +
            id +
            "/" +
            this.$store.state.emp.id_empresa
        )
        .then((response) => {
          //console.log(response.data);
          this.tipo_doc_fiscal = response.data;

          correlativo_length = response.data.correlativo.toString().length;

          formato_ceros = formato_ceros.substring(
            0,
            formato_ceros.length - correlativo_length
          );

          this.numero_control = formato
            .concat(response.data.cod_mh_doc_fiscal)
            .concat(formato_ceros)
            .concat(response.data.correlativo);
        });
    },
    incrementarCorrelativoTipoDocCSV() {
      axios
        .put("api/ConfiFacturas/IncrementarCSV", {
          id_tipo_doc_fiscal: 12,
          id_empresa: this.$store.state.emp.id_empresa,
          valor: Number(this.contador_tipo_doc_fiscal) + 1,
        })
        .then(() => {
          this.cerrarDialog();
          this.listarFacturas();
        });
    },
    incrementarCorrelativoTipoDoc() {
      axios
        .put("api/ConfiFacturas/Incrementar", {
          id_confi_factura: this.tipo_doc_fiscal.id_confi_factura,
          id_empresa: this.$store.state.emp.id_empresa,
          valor: Number(this.tipo_doc_fiscal.correlativo) + 1,
        })
        .then(() => {
          this.cerrarDialog();
          this.listarFacturas();
        });
    },
    formatoFecha(item) {
      return settings.metodos.formatoFechaHora(item);
    },
    listarCCF() {
      if (this.id_cliente <= 0) {
        this.snackbar_text = "Debe de selecionar un cliente primero.";
        this.snackbar = true;
        return;
      }

      axios
        .get(
          "api/Facturas/ListarParaNcrNdb/" +
            this.$store.state.emp.id_empresa +
            "/" +
            this.id_cliente
        )
        .then((response) => {
          this.items_ccf = response.data;

          this.dialog_tabla_CCF = true;
        });

      if (this.id_factura > 0) {
        axios
          .get(
            "api/DetalleNDB_NCR/Listar/" +
              this.id_factura +
              "/" +
              this.$store.state.emp.id_empresa
          )
          .then((response) => {
            this.lista_nrc_ndb_guardados = response.data;
          });
      }
    },
    listarServiciosDocNCR(item) {
      var format_date_now = settings.metodos.formatoFecha(new Date());
      var format_date_ccf = settings.metodos.formatoFecha(item.fecha);

      var date_now = new Date(format_date_now);
      var date_ccf = new Date(format_date_ccf);

      var milliseconds = Math.abs(date_now - date_ccf);
      var days = Math.ceil(milliseconds / (1000 * 60 * 60 * 24));
      if (days > 120) {
        this.snackbar_text = "Ya han pasado mas de 120 días para este doc.";
        this.snackbar = true;
        return;
      }

      axios
        .get("api/DetallesFacturas/Listar/" + item.id_factura)
        .then((response) => {
          this.items_detalles_fac = response.data;

          this.dialog_tabla_detalles_fac = true;
        });
    },
    agregarServiciosDocNCR() {
      this.limpiarCalculosCabecera();
      for (var i = 0; i < this.items_productos.length; i++) {
        if (this.cliente_exento) {
          this.calculoExento(this.items_productos[i].total);
          return;
        }

        if (this.items_productos[i].exento) {
          this.calculoExento(this.items_productos[i].total);
          continue;
        }
        if (this.items_productos[i].no_sujeto) {
          this.calculoNoSujeto();
          continue;
        }

        //calculo sub total
        this.sub_total = (
          Number(this.sub_total) + Number(this.items_productos[i].total)
        ).toFixed(2);

        //calculo descuento
        this.monto_descuento = 0;
        this.monto_descuento =
          Number(this.items_productos[i].cantidad) *
          Number(this.items_productos[i].precio) *
          (Number(this.items_productos[i].descuento) / 100);
        this.descuento = Number(this.descuento) + Number(this.monto_descuento);

        //calculo gravadas
        this.gravadas = (
          Number(this.sub_total) - Number(this.descuento)
        ).toFixed(2);

        //calculo iva
        if (
          this.tipo_doc_fiscal.cod_doc_fiscal == "CCF" ||
          this.tipo_doc_fiscal.cod_doc_fiscal == "NCR" ||
          this.tipo_doc_fiscal.cod_doc_fiscal == "NDB"
        ) {
          this.calculoIva(this.gravadas);
        }

        //retencion
        if (this.cliente_retencion) {
          if (element.total >= 100) {
            this.retencion = (Number(element.total) * Number(0.01)).toFixed(2);
          }
        }

        //percepcion
        if (this.cliente_percepcion) {
          if (element.total >= 100) {
            this.percepcion = (Number(element.total) * Number(0.01)).toFixed(2);
          }
        }
      }

      this.dialog_tabla_detalles_fac = false;
    },
    colorTablaCCF(item) {
      if (this.flag_new_edit == 1) {
        return "";
      } else {
        var index = this.lista_nrc_ndb_guardados.findIndex(
          (i) => i.id_factura_relacionado == item.id_factura
        );
        if (index > -1) {
          return "green";
        }
      }
    },
    colorTablaDetalles(item) {
      if (this.flag_new_edit == 1) {
        return "white";
      } else {
        var index = this.items_productos.findIndex(
          (i) => i.id_detalle_factura_ncr == item.id_detalle_factura
        );
        if (index > -1) {
          return "green";
        }
      }
    },
    fechaLimiteDebitoCredito(obj) {
      var format_date_now = settings.metodos.formatoFecha(new Date());
      var format_date_ccf = settings.metodos.formatoFecha(obj.fecha);

      var date_now = new Date(format_date_now);
      var date_ccf = new Date(format_date_ccf);

      var milliseconds = Math.abs(date_now - date_ccf);
      var days = Math.ceil(milliseconds / (1000 * 60 * 60 * 24));
      if (days > 90) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style>
#myPDF {
  width: 100%;
  height: 100%;
}
</style>