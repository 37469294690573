var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[(_vm.$store.state.emp.cod_empresa == 381)?_c('fe-swisstex'):_vm._e(),(_vm.$store.state.emp.cod_empresa == 383)?_c('fe-conab'):_vm._e(),(_vm.$store.state.emp.cod_empresa == 380)?_c('fe-unique'):_vm._e(),(_vm.$store.state.emp.cod_empresa == 337)?_c('fe-ib-marker'):_vm._e(),(_vm.$store.state.emp.cod_empresa == 445)?_c('fe-txool'):_vm._e(),(
      _vm.$store.state.emp.cod_empresa != 381 &&
      _vm.$store.state.emp.cod_empresa != 383 &&
      _vm.$store.state.emp.cod_empresa != 380 &&
      _vm.$store.state.emp.cod_empresa != 337 &&
      _vm.$store.state.emp.cod_empresa != 445
    )?_c('div',[_c('lottie',{attrs:{"options":_vm.defaultOptions,"height":700,"width":700},on:{"animCreated":_vm.handleAnimation}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }