<template>
  <v-container class="fill-height">
    <v-row justify="center" align="center">
      <v-col cols="auto" md="6">
        <v-row justify="center" align="center">
          <v-col cols="12">
            <div class="display-3 font-weight-bold blue--text text-center">
              BIENVENIDO
            </div>
          </v-col>
          <v-col cols="12">
            <h2 class="text-center">AL SISTEMA DE FACTURACIÓN ELECTRÓNICA</h2>
          </v-col>
          <v-col cols="12">
            <div class="subtitle-1 text-center">
              SE PARTE DE LAS PRIMERAS EMPRESAS EN EL SALVADOR EN REALIZAR
              FACTURAS ELETRÓNICAS
            </div>
          </v-col>
          <v-col cols="auto">
            <v-btn
              color="primary"
              block
              rounded
              @click="listarEmpresas()"
              :loading="loading"
              >Empresas</v-btn
            >
          </v-col>
          <v-col cols="auto" v-if="false">
            <v-btn color="primary" block rounded @click="LoginV2()"
              >Login v2</v-btn
            >
          </v-col>
          <v-col cols="auto" v-if="false">
            <v-btn color="primary" block rounded @click="listar()"
              >Listar v2</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="auto" md="6">
        <v-img :src="require('@/assets/modelo_1.jpg')" width="500" />
      </v-col>
    </v-row>

    <v-dialog v-model="dialog_emp" width="500" persistent scrollable>
      <v-card>
        <v-toolbar color="light-blue" dark>
          <v-toolbar-title>SELECCIONE</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog_emp = !dialog_emp">
            <v-icon size="30">fa-regular fa-circle-xmark</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pa-0">
          <v-list flat>
            <template v-for="(item, i) in items_emp">
              <v-list-item :key="item.nombre_empresa">
                <v-list-item-icon>
                  <v-btn
                    color="primary"
                    elevation="2"
                    icon
                    @click="selecEmp(item)"
                  >
                    <v-icon> fa-regular fa-hand-pointer </v-icon>
                  </v-btn>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{
                    item.nombre_empresa
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider :key="i"></v-divider>
            </template>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
import decode from "jwt-decode";
import * as CryptoJS from "crypto-js";

export default {
  data: () => ({
    dialog_emp: false,
    items_emp: [],
    emp_selected: "",
    loading: false,
    selectedItem: 0,
    key: "",
    iv: "",
  }),
  created() {
    if (!this.$store.state.emp) {
      this.listarEmpresas();
    } else {
      if (!localStorage.getItem("cod_emp")) {
        this.listarEmpresas();
      }
    }
  },
  methods: {
    listarEmpresas() {
      this.loading = true;
      axios
        .get(
          "api/UsuariosXEmpresas/ListarSinLogo/" +
            this.$store.state.usuario.idUsuario
        )
        .then((response) => {
          //console.log(response.data);
          if (response.data.length == 1 && !localStorage.getItem("cod_emp")) {
            this.selecEmp(response.data[0]);
            this.loading = false;
            return;
          }
          this.dialog_emp = true;
          this.loading = false;
          this.items_emp = response.data;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    selecEmp(item) {
      this.dialog_emp = false;
      axios
        .put("api/Usuarios/ActualizarEmpSeleccionada", {
          id_usuario: this.$store.state.usuario.idUsuario,
          emp_seleccionada: item.id_empresa,
        })
        .then(() => {
          localStorage.setItem("cod_emp", item.cod_empresa);
          this.$store.dispatch("guardarEmpresa", item);
        });
    },
    LoginV2() {
      axios
        .post("api/Usuarios/LoginV2", {
          email: "john.linares@devco.sv",
          password: "devco_JL@22",
          nit: "0614-120121-108-0",
        })
        .then((response) => {
          console.log(response.data.token);

          var token = response.data.token;
          var decode_token = decode(token);

          this.decryptUsingAES256(decode_token.c1);
        });
    },
    listar() {
      var header = {
        Authorization: "Bearer " + this.$store.state.token,
        FacturaE_API_NIT: "0614-120121-108-",
        "Content-Type": "application/json",
      };
      var configuracion = { headers: header };

      axios
        .get("api/Usuarios/ListarV2", configuracion)
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    decryptUsingAES256(decString) {
      this.key = CryptoJS.enc.Utf8.parse("4512631236589784");
      this.iv = CryptoJS.enc.Utf8.parse("4512631236589784");

      var decrypted = CryptoJS.AES.decrypt(decString, this.key, {
        keySize: 128 / 8,
        iv: this.iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });
      console.log(decrypted.toString(CryptoJS.enc.Utf8));
    },
  },
};
</script>