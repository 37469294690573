<template>
  <v-container fluid v-if="tiene_empresa">
    <v-data-table :headers="headers" :items="items">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Proveedores</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="nuevoProveedor()"> Nuevo </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:[`item.action_edit`]="{ item }">
        <v-icon class="mr-2" @click="editarProveedor(item)">
          fa-solid fa-pen-to-square
        </v-icon>
      </template>
    </v-data-table>

    <!-- VENTANA PARA PODER VER, CREAR O ACTUALIZAR UN PROVEEDOR -->
    <v-dialog v-model="dialog" width="1000" persistent>
      <v-card>
        <!-- CODIGO, NOMBRE ... -->
        <v-card-text class="pt-5 pb-0">
          <v-row no-gutters>
            <v-col cols="12" md="4" class="pa-1">
              <v-text-field
                v-model="codigo"
                label="Código"
                @input="upperLetter"
                outlined
                dense
                hide-details
                :error="errorCodigo"
              ></v-text-field>
            </v-col>

            <v-col cols="12" class="pa-1">
              <v-text-field
                v-model="nombre"
                label="Nombre"
                @input="upperLetter"
                outlined
                dense
                hide-details
                :error="errorNombre"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pa-1">
              <v-autocomplete
                v-model="id_departamento"
                :items="items_departamento"
                item-text="nombre"
                item-value="id_departamento"
                label="Departamento"
                outlined
                dense
                hide-details
                @input="listarMunicipios(id_departamento)"
                :error="errorDepartamento"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6" class="pa-1">
              <v-autocomplete
                v-model="id_municipio"
                :items="items_municipio"
                item-text="nombre"
                item-value="id_municipio"
                label="Municipio"
                outlined
                dense
                hide-details
                :error="errorMunicipio"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" class="pa-1">
              <v-text-field
                v-model="direccion"
                label="Dirección"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="pa-1">
              <v-autocomplete
                v-model="id_actividad_economica"
                :items="items_actividad_economica"
                item-text="nombre"
                item-value="id_actividad_economica"
                label="Actividad Economica"
                outlined
                dense
                hide-details
                :error="errorActividadEconomica"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="4" class="pa-1">
              <v-autocomplete
                v-model="tamaño"
                :items="items_tamaño"
                label="Tamaño"
                outlined
                dense
                hide-details
                :error="errorTamaño"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="4" class="pa-1">
              <v-text-field
                v-model="correo_1"
                label="Correo"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4" class="pa-1">
              <v-text-field
                v-model="correo_2"
                label="Correo"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4" class="pa-1">
              <v-text-field
                v-model="telefono"
                label="Teléfono"
                outlined
                dense
                hide-details
                :error="errorTelefono"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4" class="pa-1">
              <v-autocomplete
                v-model="id_identificador"
                :items="items_identificador"
                item-text="nombre"
                item-value="id_identificador"
                label="Identificador"
                outlined
                dense
                hide-details
                :error="errorIdentificador"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="4" class="pa-1">
              <v-text-field
                v-model="documento_identificador"
                label="Documento"
                outlined
                dense
                hide-details
                :error="errorDocumentoIdentificador"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4" class="pa-1">
              <v-text-field
                v-model="registro"
                label="Registro"
                outlined
                dense
                hide-details
                :error="errorDocumentoIdentificador"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <!-- CHECKBOX ... -->
        <v-row no-gutters>
          <v-col cols="12" md="6">
            <v-card class="mx-3" elevation="3">
              <v-card-text>
                <v-row no-gutters>
                  <v-col cols="12" md="6">
                    <v-checkbox
                      v-model="activo"
                      label="PROVEEDOR ACTIVO"
                      dense
                      hide-details
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-checkbox
                      v-model="con_retencion"
                      label="CON RETENCION"
                      dense
                      hide-details
                      @change="estadoRetencion()"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-checkbox
                      v-model="con_percepcion"
                      label="CON PERCEPCION"
                      dense
                      hide-details
                      @change="estadoPercepcion()"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-checkbox
                      v-model="exento"
                      label="EXENTO"
                      dense
                      hide-details
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <!-- EXTRANJERO, TIPO EXPORT, ZONA -->
        <v-card-text class="pb-0">
          <v-row justify="space-between" no-gutters>
            <v-col cols="auto" md="3">
              <v-checkbox
                v-model="exterior"
                label="EXTRANJERO"
                dense
                hide-details
              ></v-checkbox>
            </v-col>
            <v-col cols="12" md="5" class="pa-1">
              <v-autocomplete
                v-model="area"
                :items="items_area"
                item-text="text"
                item-value="value"
                label="AREA"
                outlined
                dense
                hide-details
                :error="errorArea"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>

        <!-- EXTRANJERO, TIPO EXPORT, ZONA, PAIS -->
        <v-card-text class="pb-0">
          <v-row justify="center" no-gutters>
            <v-col cols="auto" class="pa-1" align-self="center">
              <v-btn outlined @click="listarPaises('nuevo')">
                <v-icon color="grey">fa-solid fa-magnifying-glass-plus</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12" md="4" class="pa-1">
              <v-autocomplete
                v-model="id_pais"
                :items="items_paises"
                item-text="nombre"
                item-value="id_pais"
                label="País"
                outlined
                dense
                hide-details
                readonly
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="primary"
            @click="crearProveedor()"
            v-if="flag_nuevo_edit == 1"
            >Guardar</v-btn
          >
          <v-btn
            color="primary"
            @click="actualizarProveedor()"
            v-if="flag_nuevo_edit == 2"
            >Actualizar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn text @click="cerrarDialogProveedor()">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- VENTANA PARA MOSTRAR LOS PAISES -->
    <v-dialog v-model="dialog_paises" width="500">
      <v-data-table
        :headers="headers_paises"
        :items="items_paises"
        :search="search"
        @dblclick:row="setPais"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Países</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              label="BUSCAR"
              outlined
              dense
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on"
                  >fa-solid fa-circle-info</v-icon
                >
              </template>
              <span>Doble click para seleccionar el país</span>
            </v-tooltip>
          </v-toolbar>
        </template>
      </v-data-table>
    </v-dialog>

    <v-snackbar
      v-model="snackbar"
      timeout="8000"
      color="blue"
      multi-line
      elevation="24"
      outlined
      top
      right
    >
      <v-icon>{{ icon_snackbar }}</v-icon>
      {{ snackbar_text }}
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
import settings from "../../settings/settings";
import moment from "moment";

export default {
  data: () => ({
    snackbar: false,
    snackbar_text: "Mensaje",
    icon_snackbar: "fa-solid fa-circle-info",
    search: "",
    headers: [
      { text: "Proveedor", value: "codigo" },
      { text: "Nombre", value: "nombre" },
      { text: "Identificador", value: "documento_identificador" },
      { text: "Teléfono", value: "telefono" },
      { text: "Correo", value: "correo_1" },
      { text: "Editar", value: "action_edit", sortable: false },
    ],
    items: [],
    id_empresa: 0,
    //PROVEEDOR
    id_proveedor: 0,
    codigo: "",
    nombre: "",
    id_departamento: null,
    items_departamento: [],
    id_municipio: null,
    items_municipio: [],
    direccion: "",
    tamaño: "",
    items_tamaño: ["PEQUEÑO", "MEDIANO", "GRANDE", "OTROS"],
    correo_1: "",
    correo_2: "",
    telefono: "",
    id_identificador: "",
    items_identificador: [],
    documento_identificador: "",
    registro: "",

    con_retencion: false,
    activo: false,
    exento: false,
    con_percepcion: false,
    exterior: false,
    headers_paises: [
      { text: "Codigo", value: "cod_pais", align: "center" },
      { text: "Nombre", value: "nombre" },
    ],
    id_pais: "",
    items_paises: [],
    area: "",
    items_area: [
      { text: "LOCAL", value: "L" },
      { text: "IMPORTACION", value: "I" },
      { text: "INTERNACION", value: "R" },
    ],
    id_actividad_economica: "",
    items_actividad_economica: [],
    dialog: false,
    dialog_paises: false,
    flag_nuevo_edit: 1,
    errorCodigo: false,
    errorNombre: false,
    errorDepartamento: false,
    errorMunicipio: false,
    errorActividadEconomica: false,
    errorTamaño: false,
    errorTelefono: false,
    errorIdentificador: false,
    errorDocumentoIdentificador: false,
    errorArea: false,
  }),
  created() {
    //
  },
  computed: {
    tiene_empresa() {
      if (this.$store.state.emp) {
        this.listarProveedores();
        this.listarDepartamentos();
        this.listarIdentificadores();
        this.listarTipoDocFiscales();
        this.listarTipoExportacion();
        this.listarActividadEconomica();
        return true;
      }
    },
  },
  methods: {
    upperLetter() {
      this.codigo = this.codigo.toUpperCase();
    },

    listarProveedores() {
      if (this.$store.state.emp.id_empresa != undefined)
        axios
          .get("api/Proveedores/Listar/" + this.$store.state.emp.id_empresa)
          .then((response) => {
            this.items = response.data;
          });
    },

    nuevoProveedor() {
      this.flag_nuevo_edit = 1;
      this.dialog = true;
    },
    crearProveedor() {
      if (this.validarFormulario()) {
        axios
          .post("api/Proveedores/Crear", {
            id_departamento: this.id_departamento,
            id_municipio: this.id_municipio,
            id_identificador: this.id_identificador,
            id_pais: this.id_pais,
            id_empresa: this.$store.state.emp.id_empresa,
            id_actividad_economica: this.id_actividad_economica,
            codigo: this.codigo,
            nombre: this.nombre,
            direccion: this.direccion,
            tamaño: this.tamaño,
            correo_1: this.correo_1,
            correo_2: this.correo_2,
            telefono: this.telefono,
            documento_identificador: this.documento_identificador,
            registro: this.registro,
            con_retencion: this.con_retencion,
            activo: this.cliente_activo,
            exento: this.exento,
            con_percecion: this.con_percepcion,
            exterior: this.extranjero,
            area: this.area,
          })
          .then(() => {
            this.snackbar_text = "Registro creado.";
            this.snackbar = true;
            this.cerrarDialogProveedor();
            this.listarProveedores();
          })
          .catch(() => {
            this.snackbar_text = "No se pudo crear.";
            this.snackbar = true;
          });
      }
    },
    editarProveedor(item) {
      this.id_proveedor = item.id_proveedor;
      this.id_empresa = item.id_empresa;
      this.codigo = item.codigo;
      this.nombre = item.nombre;
      this.id_departamento = item.id_departamento;
      this.id_municipio = item.id_municipio;
      this.id_actividad_economica = item.id_actividad_economica;
      this.direccion = item.direccion;
      this.tamaño = item.tamaño;
      this.correo_1 = item.correo_1;
      this.correo_2 = item.correo_2;
      this.telefono = item.telefono;
      this.id_identificador = item.id_identificador;
      this.documento_identificador = item.documento_identificador;
      this.registro = item.registro;
      this.con_retencion = item.con_retencion;
      this.con_percepcion = item.con_percepcion;
      this.activo = item.activo;
      this.exento = item.exento;
      this.exterior = item.exterior;
      this.area = item.area;
      this.id_pais = item.id_pais;

      this.listarMunicipios(item.id_departamento);
      this.listarActividadEconomica(item.id_actividad_economica);
      this.listarPaises("edit");

      this.flag_nuevo_edit = 2;
      this.dialog = true;
    },
    actualizarProveedor() {
      if (this.validarFormulario()) {
        axios
          .put("api/Proveedores/Actualizar", {
            id_proveedor: this.id_proveedor,
            id_departamento: this.id_departamento,
            id_municipio: this.id_municipio,
            id_identificador: this.id_identificador,
            id_pais: this.id_pais,
            id_empresa: this.$store.state.emp.id_empresa,
            id_actividad_economica: this.id_actividad_economica,
            codigo: this.codigo,
            nombre: this.nombre,
            direccion: this.direccion,
            tamaño: this.tamaño,
            correo_1: this.correo_1,
            correo_2: this.correo_2,
            telefono: this.telefono,
            documento_identificador: this.documento_identificador,
            registro: this.registro,
            con_retencion: this.con_retencion,
            activo: this.cliente_activo,
            exento: this.exento,
            con_percecion: this.con_percepcion,
            exterior: this.extranjero,
            area: this.items_area.value,
          })
          .then(() => {
            this.snackbar_text = "Registro actualizado.";
            this.snackbar = true;
            this.cerrarDialogProveedor();
            this.listarProveedores();
          })
          .catch(() => {
            this.snackbar_text = "No se pudo actualizar.";
            this.snackbar = true;
          });
      }
    },
    cerrarDialogProveedor() {
      this.dialog = false;
      this.limpiarProveedor();
    },
    limpiarProveedor() {
      this.codigo = "";
      this.nombre = "";
      this.id_departamento = "";
      this.id_municipio = "";
      this.direccion = "";
      this.tamaño = "";
      this.correo_1 = "";
      this.correo_2 = "";
      this.telefono = "";
      this.id_identificador = "";
      this.documento_identificador = "";
      this.registro = "";
      this.con_retencion = false;
      this.activo = "";
      this.exento = false;
      this.con_percepcion = "";
      this.exterior = false;
      this.area = "";
      this.id_pais = "";
      this.id_empresa = 0;
    },
    async listarDepartamentos() {
      this.items_departamento = await (
        await settings.metodos.Departamentos()
      ).data;
    },
    async listarMunicipios(item) {
      var departamento = await (await settings.metodos.Departamento(item)).data;

      this.items_municipio = await (
        await settings.metodos.Municipios(departamento.cod_depa)
      ).data;
    },
    async listarPaises(status) {
      this.items_paises = await (await settings.metodos.Paises()).data;

      if (status == "nuevo") {
        this.dialog_paises = true;
      }
    },
    setPais(event, { item }) {
      this.id_pais = item.id_pais;
      this.dialog_paises = false;
    },
    async listarIdentificadores() {
      this.items_identificador = await (
        await settings.metodos.Identificador()
      ).data;
    },
    async listarTipoDocFiscales() {
      this.items_tipo_doc_fiscal = await (
        await settings.metodos.TipoDocFiscal()
      ).data;
    },
    async listarTipoExportacion() {
      this.items_tipo_export = await (
        await settings.metodos.TipoExportacion()
      ).data;
    },
    async listarActividadEconomica() {
      this.items_actividad_economica = await (
        await settings.metodos.Actividad_Economica()
      ).data;
    },
    formatoFecha(fecha) {
      return moment(fecha).format("YYYY-MM-dd");
    },
    estadoRetencion() {
      this.con_percepcion = false;
    },
    estadoPercepcion() {
      this.con_retencion = false;
    },

    validarFormulario() {
      let isValid = true;

      this.errorCodigo = !this.codigo;
      this.errorNombre = !this.nombre;
      this.errorDepartamento = this.id_departamento === null;
      this.errorMunicipio = this.id_municipio === null;
      this.errorActividadEconomica = this.id_actividad_economica === null;
      this.errorTamaño = !this.tamaño;
      this.errorTelefono = !this.telefono;
      this.errorIdentificador = this.id_identificador === null;
      this.errorDocumentoIdentificador = !this.documento_identificador;
      this.errorArea = !this.area;

      if (
        this.errorCodigo ||
        this.errorNombre ||
        this.errorDepartamento ||
        this.errorMunicipio ||
        this.errorActividadEconomica ||
        this.errorTamaño ||
        this.errorTelefono ||
        this.errorIdentificador ||
        this.errorDocumentoIdentificador ||
        this.errorArea
      ) {
        isValid = false;
      }

      return isValid;
    },
  },
};
</script>

<style>
.borde {
  border: 1px solid black;
  margin-top: 5px;
  margin-bottom: 5px;
}
</style>
