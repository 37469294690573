<template>
  <v-container fluid v-if="tiene_empresa">
    <v-data-table :headers="headers" :items="items" :search="search">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Servicios</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            label="Buscar"
            outlined
            dense
            hide-details=""
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="nuevoServicio()"> Nuevo </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:[`item.activo`]="{ item }">
        <v-simple-checkbox v-model="item.activo" disabled></v-simple-checkbox>
      </template>

      <template v-slot:[`item.exento`]="{ item }">
        <v-simple-checkbox v-model="item.exento" disabled></v-simple-checkbox>
      </template>

      <template v-slot:[`item.no_sujeto`]="{ item }">
        <v-simple-checkbox
          v-model="item.no_sujeto"
          disabled
        ></v-simple-checkbox>
      </template>

      <!-- Boton de editar -->
      <template v-slot:[`item.action_edit`]="{ item }">
        <v-icon class="mr-2" @click="editarServicio(item)">
          fa-solid fa-pen-to-square
        </v-icon>
      </template>
    </v-data-table>

    <v-dialog v-model="dialog" width="500" persistent>
      <v-card>
        <v-card-text>
          <v-row class="pt-3">
            <v-col cols="12" md="6">
              <v-text-field
                v-model="cod_servicio"
                label="Código"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="nom_servicio"
                label="Nombre"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row class="pt-3" no-gutters>
            <v-col cols="3" md="4">
              <v-checkbox
                label="Activo"
                v-model="activo"
                hide-details="true"
              ></v-checkbox>
            </v-col>

            <v-col cols="3" md="4">
              <v-checkbox
                label="Exento"
                v-model="exento"
                hide-details="true"
                @change="estadoExento()"
              ></v-checkbox>
            </v-col>

            <v-col cols="3" md="4">
              <v-checkbox
                label="No Sujeto"
                v-model="no_sujeto"
                hide-details="true"
                @change="estadoNoSujeto()"
              ></v-checkbox>
            </v-col>
          </v-row>

          <v-row class="pt-3" no-gutters>
            <v-col cols="3" md="4">
              <v-text-field
                label="Precio"
                model-value="10.00"
                prefix="$"
                v-model="precio"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            @click="crearServicio()"
            v-if="flag_new_edit == 1"
            >Guardar</v-btn
          >
          <v-btn
            color="primary"
            @click="actualizarServicio()"
            v-if="flag_new_edit == 2"
            >Actualizar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn text @click="dialog = false">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar"
      timeout="8000"
      color="blue"
      multi-line
      elevation="24"
      outlined
      top
      right
    >
      <v-icon>{{ icon_snackbar }}</v-icon>
      {{ snackbar_text }}
    </v-snackbar>
  </v-container>
</template>
  
  <script>
import axios from "axios";

export default {
  data: () => ({
    dialog: false,
    snackbar: false,
    icon_snackbar: "fa-solid fa-circle-info",
    snackbar_text: "Mensaje",
    headers: [
      { text: "Código", value: "cod_servicio" },
      { text: "Nombre", value: "nom_servicio" },
      { text: "Activo", value: "activo" },
      { text: "Exento", value: "exento" },
      { text: "No Sujeto", value: "no_sujeto" },
      { text: "Precio", value: "precio" },
      { text: "Editar", value: "action_edit", sortable: false },
    ],
    items: [],
    cod_servicio_original: "",
    id_servicio: 0,
    cod_servicio: "",
    nom_servicio: "",
    exento: false,
    no_sujeto: false,
    activo: false,
    precio: 0.0,
    id_emp: 0,
    flag_new_edit: 1,
    search: "",
  }),
  created() {
    //
  },
  computed: {
    tiene_empresa() {
      if (this.$store.state.emp) {
        this.listarServicios();
        return true;
      }
    },
  },
  methods: {
    listarServicios() {
      if (this.$store.state.emp.id_empresa != undefined)
        axios
          .get("api/Servicios/Listar/" + this.$store.state.emp.id_empresa)
          .then((response) => {
            this.items = response.data;
          });
    },
    nuevoServicio() {
      this.flag_new_edit = 1;
      this.limpiar();
      this.dialog = true;
    },

    async validarServicio(modo) {
      let xcodigo = "";
      let validar_cod_servicio = false;
      let mensaje_error = "";

      if (modo == 1) {
        xcodigo = this.cod_servicio;
        validar_cod_servicio = true;
      } else if (modo == 2) {
        if (this.cod_servicio != this.cod_servicio_original) {
          validar_cod_servicio = true;
        }
      }

      if (validar_cod_servicio == true) {
        let test = await axios.get(
          "api/servicios/ObtenerServicioPorCodigo/" +
            this.cod_servicio +
            "/" +
            this.$store.state.emp.id_empresa
        );
        if (test.data != "") {
          mensaje_error = "Código de Servicio ya existe. ";
        }
      }

      if (this.cod_servicio == "") {
        mensaje_error += "Debe ingresar código de servicio. ";
      }

      if (this.nom_servicio == "") {
        mensaje_error += "Debe ingresar nombre de servicio. ";
      }

      if (mensaje_error != "") {
        this.snackbar_text = mensaje_error;
        this.snackbar = true;
        return false;
      } else {
        return true;
      }
    },

    async crearServicio() {
      var es_valido = await this.validarServicio(1);

      if (es_valido == true) {
        axios
          .post("api/servicios/Crear", {
            id_empresa: this.$store.state.emp.id_empresa,
            cod_servicio: this.cod_servicio,
            nom_servicio: this.nom_servicio,
            activo: this.activo,
            exento: this.exento,
            no_sujeto: this.no_sujeto,
            precio: this.precio,
          })
          .then(() => {
            this.snackbar_text = "Se ha creado el servicio.";
            this.snackbar = true;

            this.cerrarDialog();
            this.listarServicios();
          })
          .catch(() => {
            this.snackbar_text = "No se ha creado el servicio.";
            this.snackbar = true;
          });
      }
    },
    editarServicio(item) {
      this.id_servicio = item.id_servicio;
      this.cod_servicio = item.cod_servicio;
      this.cod_servicio_original = item.cod_servicio;
      this.nom_servicio = item.nom_servicio;
      this.activo = item.activo;
      this.exento = item.exento;
      this.no_sujeto = item.no_sujeto;
      this.precio = item.precio;

      this.flag_new_edit = 2;
      this.dialog = true;
    },

    async actualizarServicio() {
      var es_valido = await this.validarServicio(2);

      if (es_valido == true) {
        axios
          .put("api/Servicios/Actualizar", {
            id_servicio: this.id_servicio,
            cod_servicio: this.cod_servicio,
            nom_servicio: this.nom_servicio,
            activo: this.activo,
            exento: this.exento,
            no_sujeto: this.no_sujeto,
            precio: this.precio,
            id_empresa: this.$store.state.emp.id_empresa,
          })
          .then(() => {
            this.snackbar_text = "Se ha actualizado el servicio.";
            this.snackbar = true;

            this.cerrarDialog();
            this.listarServicios();
          })
          .catch((error) => {
            this.snackbar_text = "No se ha actualizado el servicio. ";
            console.log(error.response);
            this.snackbar = true;
          });
      }
    },
    cerrarDialog() {
      this.dialog = false;

      this.limpiar();
    },
    limpiar() {
      this.flag_new_edit = 1;
      this.id_servicio = 0;
      this.cod_servicio = "";
      this.nom_servicio = "";
      this.activo = 0;
      this.exento = false;
      this.no_sujeto = false;
      this.precio = 0.0;
    },
    estadoExento() {
      this.no_sujeto = false;
    },
    estadoNoSujeto() {
      this.exento = false;
    },
  },
};
</script>