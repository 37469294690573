<template>
    <v-container fluid>
      <CompraSwisstex v-if="$store.state.emp.cod_empresa == 381"/>
      <CompraConab v-if="$store.state.emp.cod_empresa == 383"/>    
      <CompraIBM v-if="$store.state.emp.cod_empresa == 337"/>
      <CompraTX v-if="$store.state.emp.cod_empresa == 445"/>
    </v-container>
  </template>
  
  <script>
  import CompraConab from "./Conab/CompraConab.vue";
  import CompraSwisstex from "./Swisstex/CompraSwisstex.vue";
  import CompraIBM from "./IBMaker/CompraIBM.vue";
  import CompraTX from "./TXOOL/CompraTX.vue";

  
  export default {
    data: () => ({
      //
    }),
    components: {
      CompraSwisstex,
      CompraConab,
      CompraIBM,
      CompraTX,
    },
  };
  </script>